import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { connect } from 'react-redux';
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import { toast } from 'react-toastify';

import { toProduct } from "../../helper/paths"
import { CartItem } from "./style"
import { removeItem, increaseCountItem, decreaseCountItem } from "../../redux/cart";

const Index = ({ cart, item, removeItem, increaseCountItem, decreaseCountItem, auth, ...rest }) => {


    const increase = (id) => {
        let product = (cart.items.filter(item => parseInt(item.id) === id))[0];

        if (product.count < product.in_stock) {
            increaseCountItem(id);
        }
        else {
            toast(<div>This quantity of <span style={{ color: 'var(--main-color)', fontWeight: 'bold' }}>{product.title}</span> is not available in the stock</div>);
        }

    }
    const decrease = (id) => {
        decreaseCountItem(id);
    }
    const remove = (id) => {
        removeItem(id);
    }
    return <CartItem>
        <div className="card-item-top">
            <div className="card-item-start">
                <div className="card-item-img">
                    <img src={item.image_path} alt="" />
                    {
                        !rest.editable ? <div className="card-item-count-2">{item.count}</div>
                            : ''
                    }
                </div>
                <div className="card-item-info">
                    <Link to={toProduct(item.id)}>
                        <div>
                            <h4 className="card-item-title">{item.title}</h4>
                            <div className="card-item-prefix">
                                <small>{item.size.title ?? ''}</small>
                                <small>{item.product_type.title}</small>
                            </div>
                        </div>
                    </Link >
                </div>
            </div>
            <div className="card-item-end">
                {
                    rest.editable ?
                        <div className="card-item-count">
                            <Button onClick={() => increase(item.id)} >+</Button>
                            <span>{item.count}</span>
                            <Button onClick={() => decrease(item.id)}>-</Button>
                        </div>
                        : ''
                }

                <div className="card-item-price">
                    {rest.editable ? item.price_currency : item.total_cost + ' ' + item.currency}
                </div>
            </div>
        </div>
        {
            rest.editable ?
                <div className="card-item-bottom">
                    <div className="card-item-actions">
                        <Button className="remove" onClick={() => remove(item.id)}>
                            <FontAwesomeIcon icon={faTrash} />
                            <span>Remove</span>
                        </Button>
                    </div>
                    <div className="card-item-total">Total Price : {item.total_cost} {item.currency}</div>
                </div>
                : ''
        }



    </CartItem >

}

function mapStateToProps(state) {
    const { cart, auth } = state;
    return { cart, auth };
}
const mapDispatchToProps = dispatch => {
    return {
        removeItem: (id) => dispatch(removeItem(id)),
        decreaseCountItem: (id) => dispatch(decreaseCountItem(id)),
        increaseCountItem: (id) => dispatch(increaseCountItem(id)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);