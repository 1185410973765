//import { useState } from 'react'
import { Dropdown, DropdownMenu } from './style';

const Index = ({ children, ...rest }) => {

    //const [isDisplayed, setIsDisplayed] = useState(false);
    /*
    const handleDisplayNavList = () => {
        setIsDisplayed(!isDisplayed)
    }
*/
    return <Dropdown {...rest}>
        <DropdownMenu>
            {children}
        </DropdownMenu>
    </Dropdown>

}

export default Index