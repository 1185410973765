
import styled from 'styled-components';

export const AuthWrapper = styled.div`
    position: relative;
    z-index: 1000;
`;

export const AuthCard = styled.div`
    background: #fff;
    border:1px solid #f9f9f9;
    color:#888;
    box-shadow: 0px 2px 3px #ddd;
    width: ${props => props.wdth ? props.wdth : "500px"};
    margin: auto;
    border-radius: 5px ;
    padding: 30px 80px 40px;
    position:relative;
    .auth-card-title{
        font-size: 21px;
        color:var(--bg-color);
        text-align: center;
        margin: auto;
        margin-top: 10px ;
        margin-bottom: 50px ;
        position: relative;
        width: max-content;
    }
    .auth-card-title::before{
        content: '';
        position: absolute;
        top: 150%;
        left: 50%;
        transform: translateX(-50%);
        background: var(--main-color);
        width: 50%;
        height: 3px;
    }
    .btn{
        width: 100%;
        border: none !important;
    }
    .btn-main{
        background: var(--bg-color);
    }
    .btn-main:hover{
        color: var(--main-color);
        box-shadow: 0 1px 3px 2px #ddd !important;
    }
    .form-control{
        font-size: inherit;
        line-height:inherit;
    }
    .form-control:focus{
        outline: none;
        box-shadow:none
    }
    .form-check-input:checked {
        background-color: var(--bg-color);
        border-color: var(--bg-color);
    }
    [type="password"] {
        direction: ltr;
    }
    .auth-error{
        color: red;
        margin: 10px 0;
        text-align: center;
    }
    .form-check .form-check-input{
        float:left;
    }
    .form-check{
        padding-top: 30px;
    }
    @media (max-width:992px) {
        padding: 30px 40px 40px;
        width:auto;
    }

`;
export const InputWrapper = styled.div`
    display: flex;
    .input-icon{
        color: var(--bg-color);
        font-size: 24px;
        margin: 0;
        padding-inline-end: 15px;
        margin-inline-end: 15px;
        position: relative;
        width:50px;
        text-align: center;
    }
    .input-icon::after{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background-color: var(--main-color);
        height: 70%;
        width: 2px;
    }
    .form-control{
        border:none;
        border-bottom: 1px solid #ddd;
        background: none;
        border-radius: 0;
        padding-top: 0 ;
        padding-bottom: .2rem;
        padding-inline-start:5px;
    }
    .form-control:focus{
        outline: none;
        box-shadow:none;
        border-color:var(--main-color);
    }
    
`;