import Item from "./Item";
import { Orders } from "./style";

const Index = ({ title, orders, ...rest }) => {

 
    return <Orders {...rest}>
        <h3 className="title">{title}</h3>
        <div>
            {
                orders.map((item, index) =>
                    <Item key={index} item={item} />
                )
            }
        </div>
    </Orders>

}

export default Index;