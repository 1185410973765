
import styled from 'styled-components';

export const TotalCostBox = styled.div`
    display: flex;
    justify-content: end;
    font-size: 20px;
    margin-top: 25px;
    gap:10px;

    .total-cost-value{
        color:var(--bg-color);
        font-weight: bold;
    }   
`;
 
