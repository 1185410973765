import { useState } from 'react'
import { connect } from 'react-redux';

import { default as Topbar } from "./Topbar"
import { default as Navbar } from "./Navbar"
import { default as NavListbar } from "./NavListbar"

const Index = ({ webInfo, logout, auth }) => {


    const [isDisplayed, setIsDisplayed] = useState(false);
    const handleDisplayNavList = (val) => {
        if (val !== undefined) {
            setIsDisplayed(val)
        }
        else
            setIsDisplayed(!isDisplayed)
    }

    return <header className="sticky-top">

        <Topbar contact={webInfo.contact} features={webInfo.features} />
        <Navbar handleDisplayNav={handleDisplayNavList} setIsDisplayedNav={setIsDisplayed} isDisplayedNav={isDisplayed} logout={logout} auth={auth} />
        <NavListbar abouts={webInfo.abouts} categories={webInfo.categories} setIsDisplayedNav={setIsDisplayed} isDisplayed={isDisplayed} />
    </header>

}


function mapStateToProps(state) {
    const { webInfo } = state;
    return { webInfo };
}

export default connect(mapStateToProps, null)(Index);

