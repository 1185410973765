import { useRef, useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { faEnvelope, faMap, faUser } from '@fortawesome/free-regular-svg-icons'
import { faKey, faMobile } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux';


import FormInput from '../components/auth/Input'
import { default as SendingBox } from '../components/loading/SendingBox'
import { login } from "../redux/auth";

import { default as AuthCard } from '../components/auth/AuthCard';
import { registerReq } from '../api/requests'
import { setToken } from '../api/cookies';
import { setDocumentTitle, validateRegister } from '../helper/methods';

const Index = ({ loginDispatch, auth, webInfo }) => {



    const location = useLocation()
    const value = location.state ? location.state.from : null;

    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState('');
    const [errorsRes, setErrorsRes] = useState({});

    const emailRef = useRef('');
    const fullNameRef = useRef('');
    const passwordRef = useRef('');
    const confirmPasswordRef = useRef('');
    const addressRef = useRef('');
    const mobileRef = useRef('');
    const [errors, setErrors] = useState({
        email: '',
        fullname: '',
        password: '',
        confirm_password: '',
        address: '',
        mobile: ''
    })

    const validate = () => {
        let email = emailRef.current.value;
        let fullname = fullNameRef.current.value;
        let password = passwordRef.current.value;
        let confirm_password = confirmPasswordRef.current.value;
        let mobile = mobileRef.current.value;
        let address = addressRef.current.value;
        let res = validateRegister(email, fullname, password, confirm_password, mobile, address, errors);
        setErrors({ ...res.errorsTemp });
        return res.isValidated;

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValidated = validate();
        setErrorsRes({});
        setError('');
        if (isValidated) {
            setIsSending(true);
            registerReq({
                'email': emailRef.current.value,
                'full_name': fullNameRef.current.value,
                'password': passwordRef.current.value,
                'confirm_password': confirmPasswordRef.current.value,
                'mobile': mobileRef.current.value,
                'address': addressRef.current.value,
            }).then(res => {
                setIsSending(false);
                if (res.status) {
                    loginDispatch(res.data.user);
                    setToken(res.data.token);
                } else {
                    setErrorsRes(res.errors);
                    setError(res.message);
                }
            }).catch(e => {
                setIsSending(false);
            });
        }
    }
    useEffect(() => {
        setDocumentTitle(webInfo.site_name, "Create Account")
    }, []);

    if (auth.isAuthenticated)
        return <Redirect to={value ? value.pathname : "/"} />

    return <div className="page auth-page overlay-wrapper">

        <div className="overlay-box"> </div>
        <Container>
            <AuthCard title="Create Account"  >
                {
                    isSending ? <SendingBox /> : ''
                }
                <Form onSubmit={handleSubmit}>
                    <FormInput icon={faEnvelope} type="email" placeholder="Enter Email " ref={emailRef} error={errors.email || errorsRes.email} name="email" />
                    <FormInput icon={faUser} type="text" placeholder="Enter Full Name" ref={fullNameRef} error={errors.fullname || errorsRes.fullname} name="fullname" />
                    <FormInput icon={faKey} type="password" placeholder="Enter Password" ref={passwordRef} error={errors.password || errorsRes.password} name="password" />
                    <FormInput icon={faKey} type="password" placeholder="Enter Confirm Password" ref={confirmPasswordRef} error={errors.confirm_password || errorsRes.confirm_password} name="confirm_password" />
                    <FormInput icon={faMobile} type="text" placeholder="Enter Your Mobile" ref={mobileRef} error={errors.mobile || errorsRes.mobile} name="mobile" />
                    <FormInput icon={faMap} type="text" as="textarea" placeholder="Enter Your Address" ref={addressRef} error={errors.address || errorsRes.address} name="address" />

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    {
                        error ? <div className='auth-error'>{error}</div> : ''
                    }
                    <Button className="btn-main" type="submit">
                        Submit
                    </Button>
                </Form>
                <div className="text-center mt-4">
                    <Link to="/login" > I have an account </Link>
                </div>
            </AuthCard>
        </Container >
    </div >

}

function mapStateToProps(state) {
    const { auth, webInfo } = state;
    return { auth, webInfo };
}
const mapDispatchToProps = dispatch => {
    return {
        loginDispatch: (user) => dispatch(login(user)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);