

import React, { useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { default as CartItem } from '../components/cart/Item';
import { default as CartTotalBox } from '../components/cart/CartTotalBox';

import { default as TotalCostBox } from '../components/total-cost-box/TotalCostBox';
import { checkout, updateShipping } from "../redux/cart";
import { Link } from "react-router-dom";
import { CHECKOUT, HOME } from "../helper/paths";
import { Redirect } from "react-router-dom";



const Index = ({ cart, auth, updateShippingDispatch }) => {

    const [errorsRes, setErrorsRes] = useState({});

    const [error, setError] = useState('');

    const [checkout, setCheckout] = useState(false);

    const checkOut = (mobile, address, fullname, email, coupon) => {
        setError('');
        console.log(coupon)
        const shippment = { mobile, address, fullname, email, coupon };
        updateShippingDispatch({ ...shippment });
        setCheckout(true);
    }
    const addCoupon = (coupon) => {
        console.log(coupon)
        const shippment = { ...cart.shipping, coupon };
        updateShippingDispatch({ ...shippment });
    }

    if (checkout)
        return <Redirect to={CHECKOUT} />


    return <div className="page checkout-page">
        <Container>
            {
                <>
                    {
                        cart.items.length > 0 ?
                            <Row>
                                <Col md="7">
                                    <CartTotalBox addCoupon={addCoupon} setError={setError} auth={auth} cart={cart} errorsRes={errorsRes} error={error} totalCost={cart.total_cost} checkOut={checkOut} />
                                </Col>
                                <Col md="5">

                                    {
                                        cart.items.map((item, index) =>
                                            <div key={index}>
                                                <CartItem item={item} editable={false} />
                                            </div>
                                        )
                                    }
                                    <TotalCostBox total_cost={cart.total_cost} currency={cart.currency} discount={cart.shipping.coupon.type ? cart.shipping.coupon : 0} />

                                </Col>

                            </Row>
                            : <div className="text-center mt-4" ><Link style={{ background: '#f2f2f2', padding: '15px 100px', margin: 'auto', width: 'max-content' }} to={HOME}> Return To Home </Link> </div>

                    }
                </>
            }
        </Container>
    </div>
}


function mapStateToProps(state) {
    const { cart, auth } = state;
    return { cart, auth };
}
const mapDispatchToProps = dispatch => {
    return {
        checkOutDispatch: () => dispatch(checkout()),
        updateShippingDispatch: (info) => dispatch(updateShipping(info)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);