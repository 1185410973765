import { useState } from 'react';
import { connect } from 'react-redux';

import { Sidebar } from "./style";
import { default as FilterBox } from './FilterBox'
import { default as FilterChoice } from './FilterChoice'

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";

const Index = ({ item, handleDisplayFilter, handleFilter, handleSort, webInfo, filter_brands, ...rest }) => {

    const [brands, setBrands] = useState(webInfo.brands ? webInfo.brands : []);

    const handleChangeFilter = (e) => {
        let brandsTemp = webInfo.brands;
        setBrands([...brandsTemp.filter(item => (item.title.toLowerCase()).includes((e.target.value).toLowerCase()))]);
    }

    return <Sidebar {...rest}>
        <Button className="bg-grey mobile-btn "
            style={{ color: 'var(--bg-color)', fontSize: '25px', border: 'none', padding: '0', background: 'none', position: 'absolute', top: '10px' }}
            onClick={handleDisplayFilter}
        ><FontAwesomeIcon icon={faWindowClose} /></Button>
        <div className="filter-wrapper">
            <FilterBox title="Order By">
                <FilterChoice item={item} onChange={handleSort} name="order" type="select" >
                    <option value="date-asc">Date: New to Old</option>
                    <option value="date-desc">Date: Old to New</option>
                    <option value="title-asc">Title: A to Z</option>
                    <option value="title-desc">Title: Z to A</option>
                    <option value="price-desc">Price: High to Low</option>
                    <option value="price-asc">Price: Low to High</option>
                </FilterChoice>
            </FilterBox>
            <FilterBox title="Gender">
                {
                    webInfo.products_types ?
                        webInfo.products_types.map((type, index) =>
                            <FilterChoice item={item} key={index} onChange={handleFilter} title={type.title} id={'type_' + type.id} value={type.id} name="types" type="checkbox" />
                        )
                        : ''
                }
            </FilterBox>
            {
                filter_brands ?
                    <FilterBox title="Brand" search={true} handleChangeFilter={handleChangeFilter}>

                        {
                            brands.map((brand, index) =>
                                <FilterChoice item={item}
                                    checked={(filter_brands.filter(item => parseInt(item) === brand.id).length) > 0 ? true : false} onChange={handleFilter} key={index}
                                    title={brand.title} id={'brand_' + brand.id} value={brand.id} name="brands" type="checkbox" />
                            )

                        }
                    </FilterBox> : ''
            }

        </div>
    </Sidebar>

}

function mapStateToProps(state) {
    const { webInfo } = state;
    return { webInfo };
}

export default connect(mapStateToProps, null)(Index);