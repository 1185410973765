
import ReactRating from "react-rating";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as faStarFill } from "@fortawesome/free-solid-svg-icons"

const Index = ({ val, bgSymbol, emSymbol, ...rest }) => {


    return <ReactRating
        initialRating={val}
        emptySymbol={<FontAwesomeIcon icon={faStar} style={{ color: emSymbol }} />}
        fullSymbol={<FontAwesomeIcon icon={faStarFill} style={{ color: bgSymbol }} />}
        {...rest} />
}

export default Index