
import { useEffect, useState } from "react";
import Fade from 'react-reveal/Fade';

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import './style.css';
import { getSlidersReq } from '../../api/requests'
import SwiperCore, {
    Navigation, Autoplay, EffectFade, EffectFlip
} from 'swiper';

SwiperCore.use([EffectFade, EffectFlip, Autoplay, Navigation]);


const Index = () => {

    const [sliders, setSliders] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        getSlidersReq().then(res => {
            setIsLoading(false);
            if (res.status) {
                setSliders(res.items);
            } else {
                setError(res.message);
            }
        }).catch(e => {
            setIsLoading(false);
        });
    }, []);

    return <section className="slider-section">
        <Swiper
            style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }}
            spaceBetween={0}
            navigation={false}
            className="home-slider"
            watchSlidesProgress
            slidesPerView={1}
            speed={2000}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            effect={"fade"}
        >
            {
                sliders ?
                    sliders.map((slide, index) =>
                        <SwiperSlide key={index} >

                            <div style={{ height: '100%' }}>
                                <img src={slide.image_path} />
                                <div className="home-slide-content overlay-box text-center " >
                                    <div>
                                        <Fade top>
                                            <h1 className="home-slide-title">{slide.title}</h1>
                                            <div className="short-desc">{slide.short_description}</div>
                                        </Fade>
                                    </div>
                                </div>
                            </div>

                        </SwiperSlide>
                    ) : ''
            }


        </Swiper>
    </section>
}

export default Index