
import { Col, Container, Row } from 'react-bootstrap';
//import { projects as projectsList, constractors as constractorsList } from '../helper/data'
import { default as LoadingPage } from '../components/loading/LoadingPage';
import { useEffect, useState } from 'react';
import { getSearchReq } from '../api/requests';
import { default as PageTitle } from '../components/page-title/Index'
import { default as ProductItem } from '../components/product/Index';
import { default as SearchRow } from '../components/search-row/SearchRow';
import { default as Brand } from '../components/brands/Item';
import { default as CategoryItem } from '../components/categories/Item';
import { Fade } from 'react-reveal';

const Index = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const queryParams = new URLSearchParams(window.location.search)
    const query = queryParams.get("query")
    const getData = () => {
        setIsLoading(true);
        if (query !== "") {
            getSearchReq(query).then(res => {
                setIsLoading(false);
                if (res.status) {
                    setProducts(res.data.se_products);
                    setCategories(res.data.se_categories);
                    setBrands(res.data.se_brands);
                } else {
                    setError(res.message);
                }
            }).catch(e => {
                setIsLoading(false);
            });
        }
    }
    useEffect(() => {
        getData();
    }, [query]);

    return <div className="page">
        <Container>

            {
                !isLoading ?
                    <>
                        <PageTitle title='Search Results' className=""></PageTitle>
                        <div>
                            {
                                products.length ?
                                    <SearchRow title="Products">
                                        <div>
                                            <Row>
                                                {
                                                    products.map((item, index) =>
                                                        <Col md="3" key={index}>
                                                            <Fade bottom style={{ height: '100%' }}>
                                                                <ProductItem item={item} style={{ height: '100%' }} />
                                                            </Fade>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        </div>
                                    </SearchRow>
                                    : ''
                            }
                            {
                                categories.length > 0 ?
                                    <SearchRow title="Categories">
                                        <div className="pt-4" >
                                            <Row>
                                                {
                                                    categories.map((item, index) =>
                                                        <Col md="3" key={index}>
                                                            <Fade bottom style={{ height: '100%' }}>
                                                                <CategoryItem item={item} style={{ height: '100%' }} />
                                                            </Fade>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        </div>
                                    </SearchRow>
                                    : ''
                            }

                            {
                                brands.length ?
                                    <SearchRow title="Brands">
                                        <div>
                                            <Row>
                                                {
                                                    brands.map((item, index) =>
                                                        <Col md="3" key={index}>
                                                            <Fade bottom>
                                                                <Brand item={item} />
                                                            </Fade>
                                                        </Col>
                                                    )
                                                }
                                            </Row>
                                        </div>
                                    </SearchRow>
                                    : ''
                            }
                        </div>
                    </>
                    : <LoadingPage />
            }

        </Container>
    </div >
}

export default Index;