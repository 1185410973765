import { ProductInfoItem } from "./style"


const Index = ({ label, children }) => {


    return <ProductInfoItem>
        <label className="label">{label} : </label>
        <div>
            {children}
        </div>
    </ProductInfoItem>
}

export default Index