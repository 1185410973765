


import { DrawerItem, DrawerItemIcon } from './drawer.style';

const Index = ({ icon, children }) => {


    return <DrawerItem>
        <DrawerItemIcon>{icon}</DrawerItemIcon>
        {children}
    </DrawerItem >
}

export default Index