import { Container } from "react-bootstrap";
import { default as PageTitle } from "../components/page-title/Index";




const Index = ({ item }) => {
    return <div className='page'  >
        <Container>
            <div className="img-box" style={{ maxWidth: '300px', width: '300px', display: 'inline-block', float: 'left' }}>
                <img src={item.image_path} alt="" />
            </div>
            <div>
                <PageTitle title={item.title} />
            </div>
            <p dangerouslySetInnerHTML={{ __html: item.description }} />
        </Container>

    </div>
}

export default Index;