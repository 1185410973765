import { useRef, useState, useEffect, useCallback } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import FormInput from '../components/auth/Input'
import { default as AuthCard } from '../components/auth/AuthCard'
import { default as SendingBox } from '../components/loading/SendingBox'
import { loginReq } from '../api/requests'

import { login } from "../redux/auth";

import { REGISTER, FORGET_PASS } from "../helper/paths"
import { setToken } from '../api/cookies';
import { setDocumentTitle, validateLogin } from '../helper/methods';


const Index = ({ webInfo, login, auth }) => {

    const location = useLocation()
    const value = location.state ? location.state.from : null;

    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState('');
    const [errorsRes, setErrorsRes] = useState({});

    const emailRef = useRef('');
    const passwordRef = useRef('');
    const [errors, setErrors] = useState({
        email: '',
        password: ''
    })

    const validate = () => {
        let email = emailRef.current.value;
        let password = passwordRef.current.value;
        let res = validateLogin(email, password, errors);
        setErrors({ ...res.errorsTemp });
        return res.isValidated;
    }
    const handleLogin = useCallback((e) => {
        e.preventDefault();
        setError('')
        setErrorsRes({})
        let isValidated = validate();
        if (isValidated) {
            setIsSending(true)
            loginReq({
                'email': emailRef.current.value,
                'password': passwordRef.current.value,
            }).then(res => {
                //console.log(res);
                setIsSending(false);

                if (res.status) {
                    login(res.data.user)
                    setToken(res.data.token);
                    //toast.notify(res.message);
                }
                else if (res.errNum === 401)
                    setError(res.message);

                else
                    setErrorsRes(res.errors);

            }).catch(e => {
                setIsSending(false);
            });
        }
    });
    useEffect(() => {
        setDocumentTitle(webInfo.site_name , "Login")
    }, []);

    if (auth.isAuthenticated)
        return <Redirect to={value ? value.pathname : "/"} />

    return <div className="page auth-page overlay-wrapper">
        <div className="overlay-box"> </div>
        <Container>

            <AuthCard title="Login" >
                {
                    isSending ? <SendingBox /> : ''
                }
                <Form onSubmit={handleLogin} >
                    <FormInput icon={faEnvelope} type="email" placeholder="Enter Your Email" ref={emailRef} error={errors.email || errorsRes.email} name="email" />
                    <FormInput icon={faKey} type="password" placeholder="Enter Your Password" ref={passwordRef} error={errors.password || errorsRes.password} name="password" />

                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" />
                    </Form.Group>
                    {
                        error ? <div className='auth-error'>{error}</div> : ''
                    }
                    <Button className="btn-main" type="submit">
                        Submit
                    </Button>
                </Form>
                <div className="text-center mt-4 d-flex justify-content-center" style={{ gap: '15px' }}>
                    <Link to={REGISTER} >I don't have an account</Link>
                    <Link to={FORGET_PASS} >Forget password</Link>
                </div>
            </AuthCard>

        </Container>
    </div>

}

function mapStateToProps(state) {
    const { webInfo, auth } = state;
    return { webInfo, auth };
}
const mapDispatchToProps = dispatch => {
    return {
        login: (user) => dispatch(login(user)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);