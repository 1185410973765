

import React, { useEffect } from "react";
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';

import { default as CartItem } from '../components/cart/Item';
import { default as TotalCostBox } from '../components/total-cost-box/TotalCostBox';

import { default as PageTitle } from "../components/page-title/Index";
import { checkout } from "../redux/cart";
import { SHIPPMENT } from "../helper/paths";
import { Link } from "react-router-dom";
import { setDocumentTitle } from "../helper/methods";



const Index = ({ cart, webInfo }) => {


    useEffect(() => {
        setDocumentTitle(webInfo.site_name, "Cart");
    }, []);

    return <div className="page cart-page">
        <Container>
            {
                <>
                    <PageTitle title="Cart" ></PageTitle>

                    {

                        cart.items.length > 0 ?

                            <div>
                                <div>
                                    {

                                        cart.items.map((item, index) =>
                                            <div key={index}>
                                                <CartItem item={item} editable={true} />
                                            </div>
                                        )

                                    }
                                </div>
                                <div className="my-2">
                                    
                                    <TotalCostBox total_cost={cart.total_cost} currency={cart.currency} discount={cart.shipping.coupon.type ? cart.shipping.coupon : 0} />
                                </div>
                                <div className="mt-4 d-flex justify-content-end" style={{ gap: '10px' }}>
                                    <Link className="btn btn-primary" to={SHIPPMENT} > Shipment </Link>
                                </div>
                            </div>


                            : <div className="text-center" style={{ background: '#f2f2f2', padding: '15px 100px', margin: 'auto', width: 'max-content' }}>No Items</div>

                    }
                </>
            }
        </Container>
    </div>
}


function mapStateToProps(state) {
    const { cart, auth, webInfo } = state;
    return { cart, auth, webInfo };
}
const mapDispatchToProps = dispatch => {
    return {
        checkOutDispatch: () => dispatch(checkout())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);