

export const ACTIONS = {
    UPDATE_INFO: "UPDATE_INFO",
};

export function update(info) { return { type: ACTIONS.UPDATE_INFO, payload: info } }

const initialState = {
    socials: [],
    about: {},
    categories: [],
    contact: {},
    brands: [],
    products_types: [],
    features: {},
    section_titles: {
        new: '',
        top: '',
        brands: ''
    },
    site_name: ''
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_INFO:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

