
export const ACTIONS = {
    ADD_ITEM: "ADD_ITEM",
    REMOVE_ITEM: "REMOVE_ITEM",
    INCREASE_COUNT: "INCREASE_COUNT",
    DECREASE_COUNT: "DECREASE_COUNT",
    UPDATE_SHIPPING: "UPDATE_SHIPPING",
    CHECK_OUT: "CHECK_OUT"
};


export function addItem(item) { return { type: ACTIONS.ADD_ITEM, payload: item } }
export function removeItem(id) { return { type: ACTIONS.REMOVE_ITEM, payload: id } }
export function checkout() { return { type: ACTIONS.CHECK_OUT } }
export function decreaseCountItem(id) { return { type: ACTIONS.DECREASE_COUNT, payload: id } }
export function increaseCountItem(id) { return { type: ACTIONS.INCREASE_COUNT, payload: id } }
export function updateShipping(info) { return { type: ACTIONS.UPDATE_SHIPPING, payload: info } }

const shipping = {
    email: '',
    fullname: '',
    address: '',
    mobile: '',
    coupon: {
        id: '',
        code: ''
    }
}
const initialState = localStorage.getItem('cart') ?
    {
        ...JSON.parse(localStorage.getItem('cart')),
        currency: '$',
        shipping: { ...shipping, coupon: JSON.parse(localStorage.getItem('cart')).shipping.coupon }
    } : {
        items: [],
        total_cost: 0,
        currency: '$',
        shipping: shipping
    };

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.ADD_ITEM: {
            let id = action.payload.id;
            let index = state.items.findIndex(item => item.id === id);
            let items = state.items;
            let total_cost = state.total_cost;
            if (index !== -1) {
                let item = items[index];
                item.count += action.payload.count;
                item.total_cost = (item.count * item.price);
                total_cost += (item.price * action.payload.count)
            }
            else {
                items.push(action.payload);
                total_cost += (action.payload.price * action.payload.count)
            }
            localStorage.setItem('cart', JSON.stringify({
                ...state,
                items,
                total_cost
            }))
            return {
                ...state,
                items,
                total_cost
            };
        }

        case ACTIONS.REMOVE_ITEM: {
            let id = action.payload;
            let index = state.items.findIndex(item => item.id === id);
            let items = state.items
            let item = items[index];
            let total_cost = state.total_cost - item.total_cost;
            items = state.items.filter(item => item.id !== id);
            localStorage.setItem('cart', JSON.stringify({
                ...state,
                items,
                total_cost
            }))
            return {
                ...state,
                items,
                total_cost
            };
        }

        case ACTIONS.INCREASE_COUNT: {
            let id = action.payload;
            let index = state.items.findIndex(item => item.id === id);
            let items = state.items;
            let total_cost = state.total_cost;
            if (index !== -1) {
                let item = items[index];
                item.count += 1;
                item.total_cost = (item.count * item.price);
                total_cost = state.total_cost + parseInt(item.price);
            }
            localStorage.setItem('cart', JSON.stringify({
                ...state,
                items,
                total_cost
            }))
            return {
                ...state,
                items: [...items],
                total_cost
            };
        }

        case ACTIONS.DECREASE_COUNT: {
            let id = action.payload;
            let index = state.items.findIndex(item => item.id === id);
            let items = state.items;
            let item = items[index];
            let total_cost = state.total_cost;
            if (item.count > 1) {
                item.count = item.count - 1;
                item.total_cost = (item.count * item.price);
                total_cost = state.total_cost - parseInt(item.price);
            }
            localStorage.setItem('cart', JSON.stringify({
                ...state,
                items,
                total_cost
            }))
            return {
                ...state,
                items,
                total_cost
            };
        }
        case ACTIONS.UPDATE_SHIPPING: {
            return {
                ...state,
                shipping: action.payload
            };
        }
        case ACTIONS.CHECK_OUT: {
            localStorage.removeItem('cart');
            return {
                items: [],
                total_cost: 0,
                currency: '$',
                shipping
            };
        }

        default:
            return state;
    }
};