




export const setDocumentTitle = (site_name, title, description, image, keywords) => {
    document.title = site_name + " | " + title;
    /*
    document.querySelector("meta[name='description']").setAttribute("content", description ?? "Powered By Swan Technology");
    document.querySelector("meta[name='author']").setAttribute("content", site_name);
    document.querySelector("meta[name='keywords']").setAttribute("content", keywords ?? "Powered By Swan Technology");

    document.querySelector("meta[property='og:title']").setAttribute("content", title);
    document.querySelector("meta[property='og:type']").setAttribute("content", site_name);
    document.querySelector("meta[property='og:url']").setAttribute("content", window.location.href);
    document.querySelector("meta[property='og:image']").setAttribute("content", image ?? "");

    document.querySelector("meta[name='twitter:title']").setAttribute("content", title);
    document.querySelector("meta[name='twitter:description']").setAttribute("content", description ?? "Powered By Swan Technology");
    document.querySelector("meta[name='twitter:image']").setAttribute("content", image ?? "");
*/
}

export const filterProducts = (name, checked, value, filter) => {

    if (name === 'brands') {
        let brands = filter.brands;
        if (checked)
            brands.push(value)
        else
            brands = brands.filter(brand => parseInt(brand) !== parseInt(value))
        filter = {
            ...filter,
            brands
        }
    }
    else if (name === 'types') {
        let types = filter.types;
        if (checked)
            types.push(value)
        else
            types = types.filter(type => parseInt(type) !== parseInt(value))
        filter = {
            ...filter,
            types
        }
    }
    return filter;
}


export const validateRegister = (email, fullname, password, confirm_password, mobile, address, errors) => {
    let isValidated = true;
    let errorsTemp = errors;
    if (email === '') {
        errorsTemp.email = 'Email is required'
        isValidated = false
    }
    else {
        errorsTemp.email = ''
    }
    if (fullname === '') {
        errorsTemp.fullname = 'Full Name is required'
        isValidated = false
    }
    else {
        errorsTemp.fullname = ''
    }
    if (password === '') {
        errorsTemp.password = 'Password is required'
        isValidated = false
    }
    if (password !== confirm_password) {
        errorsTemp.confirm_password = `Password doesn't match`
        isValidated = false
    }
    else {
        errorsTemp.confirm_password = ''
    }
    if (mobile === '') {
        errorsTemp.mobile = 'Mobile is required'
        isValidated = false
    }
    else {
        errorsTemp.mobile = ''
    }
    if (address === '') {
        errorsTemp.address = 'Address is required'
        isValidated = false
    }
    else {
        errorsTemp.address = ''
    }
    //setErrors({ ...errorsTemp });
    return { errorsTemp, isValidated };

}

export const validateLogin = (email, password, errors) => {
    let isValidated = true;
    let errorsTemp = errors;

    if (email === '') {
        errorsTemp.email = 'Email is required'
        isValidated = false
    }
    else {
        errorsTemp.email = ''
    }
    if (password === '') {
        errorsTemp.password = 'Password is required'
        isValidated = false
    }
    else {
        errorsTemp.password = ''
    }
    return { errorsTemp, isValidated };
}


export const validateContact = (email, name, subject, message, errors) => {
    let isValidated = true;
    let errorsTemp = errors;
    if (email === '') {
        errorsTemp.email = 'Email is required'
        isValidated = false
    }
    else {
        errorsTemp.email = ''
    }
    if (name === '') {
        errorsTemp.name = 'Name is required'
        isValidated = false
    }
    else {
        errorsTemp.name = ''
    }
    if (subject === '') {
        errorsTemp.subject = 'Subject is required'
        isValidated = false
    }
    else {
        errorsTemp.subject = ''
    }
    if (message === '') {
        errorsTemp.message = `Message is required`
        isValidated = false
    }
    else {
        errorsTemp.message = ''
    }
    //setErrors({ ...errorsTemp });
    return { errorsTemp, isValidated };

}



export const validateCheckout = (mobile, address, fullname, couponCode, couponId, isAuthenticated, errors) => {
    let isValidated = true;
    let errorsTemp = errors;
    if (mobile === '') {
        errorsTemp.mobile = 'Mobile is required'
        isValidated = false
    }
    else {
        errorsTemp.mobile = ''
    }
    if (address === '') {
        errorsTemp.address = 'Address is required'
        isValidated = false
    }
    else {
        errorsTemp.address = ''
    }
    if (!isAuthenticated) {
        if (fullname === '') {
            errorsTemp.fullname = 'Full Name is required'
            isValidated = false
        }
        else {
            errorsTemp.fullname = ''
        }
    }
    if (couponCode !== '' && couponId === '') {
        errorsTemp.couponCode = 'Coupon is not checked'
        isValidated = false
    }
    else {
        errorsTemp.coupon = ''
    }
    //setErrors({ ...errorsTemp });
    return { errorsTemp, isValidated };

}
