
import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { default as ProfileHead } from '../components/profile/ProfileHead';
import { default as ProfileInfo } from '../components/profile/ProfileInfo';
import { default as Orders } from '../components/order/Index';
import { default as PersonalInfoEditModal } from '../components/profile/PersonalInfoEditModal';
import { default as ImagesEditModal } from '../components/profile/ImagesEditModal';
import { default as LoadingPage } from '../components/loading/LoadingPage';
 
import {   getProfileReq } from '../api/requests'; 

const Index = () => {

    const [user, setUser] = useState({});

    const [updated, setUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const [showPersonalInfoEditModal, setShowPersonalInfoEditModal] = useState(false);
    const handleClosePersonalInfoEditModal = () => setShowPersonalInfoEditModal(false);

    const [showImagesEditModal, setShowImagesEditModal] = useState(false);
    const handleCloseImagesEditModal = () => setShowImagesEditModal(false);

    const getData = () => {
        document.title = " ";
        getProfileReq().then(res => {
            setIsLoading(false);
            if (res.status) {
                setUser(res.user);
                document.title = res.user.full_name;
            } else {
                setError(res.message);
            }
        }).catch(e => {
            setIsLoading(false);
        });

    }
    useEffect(() => {
        getData();
    }, [updated]);


    return <div className="page">
        {
            user.id ? <>

                <PersonalInfoEditModal show={showPersonalInfoEditModal} setUpdated={() => setUpdated(!updated)}
                    handleClose={handleClosePersonalInfoEditModal} user={user} />
                <ImagesEditModal show={showImagesEditModal} setUpdated={() => setUpdated(!updated)}
                    handleClose={handleCloseImagesEditModal} images={{ img: user.img_path, cover: user.cover_path }} />
            </> : ''
        }

        {
            isLoading ?
                <LoadingPage /> :
                <Container>
                    <Row>
                        <Col lg="12" >
                            <ProfileHead img={user.img_path} profile={null} full_name={user.full_name} setShowImagesEditModal={() => setShowImagesEditModal(true)} setShowPersonalInfoEditModal={() => setShowPersonalInfoEditModal(true)} />
                            <Row>
                                <Col lg="3">
                                    <ProfileInfo {...user} />
                                </Col>
                                <Col lg="9" >

                                    <Orders title="My Order" orders={user.orderable ?? []} />

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
        }
    </div>
}

export default Index;
