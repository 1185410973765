 import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { default as Item } from './Item';

const Index = ({ categories }) => {
 

    return <div className="new-products-section section " style={{ borderBottom: '1px solid #ddd' }}>
        <Container>
            <Row>
                {
                    categories.map((category, index) =>
                        <>
                            {
                                category.products.length > 0 ? <Col md={6} key={index}>
                                    <Fade left>
                                        <Item item={category} />
                                    </Fade>
                                </Col> : ''
                            }
                        </>

                    )
                }
            </Row>

        </Container>
    </div >
}

export default Index