
import styled from 'styled-components';


export const SearchRow = styled.div`
    padding-bottom:20px;
    .title{
        position:relative;
        font-size:17px;
        padding-inline-start:15px;
        margin-bottom:40px;
    }
    .title:before{
        content:'';
        position:absolute;
        left:0;
        top:0;
        width:5px;
        height:100%;
        background:var(--main-color);
    }
`;