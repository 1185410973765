
import React, { useState } from 'react';
import { Modal, Form, FormGroup, FormControl, FormText } from 'react-bootstrap';
import { Btn } from '../style';
import NoProfileImg from '../../assets/imgs/no-profile-img.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { updatePersonalImagesReq } from '../../api/requests';

const Index = ({ show, handleClose, images, setUpdated }) => {

    const [imgs, setImgs] = useState({
        img: null
    });


    const hiddenProfileInput = React.useRef(null);

    const handleProfileInputClick = event => {
        event.preventDefault()
        hiddenProfileInput.current.click();
    };

    const handleInputFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let val = event.target.files[0];
            setImgs({
                ...imgs,
                [event.target.name]: val
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('img', imgs.img);
        updatePersonalImagesReq(formData).then(res => {
            if (res.status) {
                setUpdated();
                handleClose();
            } else {

            }
        }).catch(e => e);

    }
    return <Modal show={show ? 1 : 0} onHide={handleClose} >
        <Modal.Header closeButton style={{ padding: '9px 15px' }}>
            <Modal.Title style={{ fontSize: '15px' }}>Edit Profile Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit} >

                <FormGroup controlId="img" >
                    <FormControl size="sm" type="file" accept="image/*" ref={hiddenProfileInput} style={{ display: 'none' }}
                        onChange={handleInputFileChange} name="img" />
                    <FormText className="text-muted input-error">
                    </FormText>
                </FormGroup>
                <Btn onClick={handleProfileInputClick} style={{
                    fontSize: '15px',
                    padding: '2px 6px'
                }} bg="#ddd">
                    <FontAwesomeIcon icon={faPencilAlt} /> Edit
                </Btn>
                <div className='text-center my-3 mb-4'>
                    <img alt="" style={{ margin: 'auto', width: '200px', height: '200px', borderRadius: '50%' }}
                        src={imgs.img ? URL.createObjectURL(imgs.img) : images.img ? images.img : NoProfileImg} />
                </div>





                <div style={{ borderTop: '1px solid #f2f2f2', paddingTop: '5px' }}>
                    <Btn type="submit">
                        Save
                    </Btn>
                    <Btn type="button" onClick={handleClose} bg="#ddd">
                        Cancel
                    </Btn>
                </div>

            </Form>
        </Modal.Body>
    </Modal>
}


export default Index;
/*
 <div className='text-center my-3 mb-4'>
                    <img style={{ margin: 'auto', width: '200px', height: '200px', borderRadius: '50%' }}
                        src={imgs.profile_photo ?  NoProfileImg : NoProfileImg} />
                </div>
              
 URL.createObjectURL(imgs.profile_photo)
 URL.createObjectURL(imgs.cover)
                <FormGroup className="mb-3" controlId="description">
                    <FormControl size="sm" type="file" multiple placeholder="صورة" accept="image/*" ref={hiddenFileInput} style={{ display: 'none' }}
                        onChange={handleInputFileChange} name="image" value={newProject.image} />
                    <FormText className="text-muted input-error">

                    </FormText>
                </FormGroup>
*/