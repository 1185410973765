import { SendingBox } from "./style"
import { ThreeDots } from 'react-loading-icons'
const Index = ({ bg }) => {
    return <SendingBox bg={ bg ? bg : 'var(--bg-opacity-color)' } >
        <div>
            <span>Please Waiting</span>
            <div>
                <ThreeDots />
            </div>
        </div>
    </SendingBox>
}

export default Index