import { Link } from "react-router-dom"
import { toBrand } from "../../helper/paths"

const Index = ({ item }) => {

    return <>
        <Link to={item.link ? item.link : toBrand(item.id)}>
            <div style={{ border: '1px solid #ddd', padding: '7px', marginBottom: '15px' }}>
                <img src={item.image_thumb_path} className="w-100" />
            </div>
        </Link >

    </>
}
export default Index