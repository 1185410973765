

import { Fade } from "react-reveal";

import { PageTitle } from "./style.js";

const Index = (props) => {
    const { title, children, ...rest } = props
    return <Fade left>
        <PageTitle {...rest}>
            <h1 className="title">
                {title}
            </h1>

            <div className="section-line"><span></span></div>
            <div className="">
                {children}
            </div>
        </PageTitle>
    </Fade>
};

export default Index