
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom"
import { connect } from 'react-redux';

import { DropdownLink, Navbar } from './style';
import { default as Dropdown } from './Dropdown';
import { default as DropdownItem } from './DropdownItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faUser, faCartPlus, faList, faSignOutAlt, faKey } from '@fortawesome/free-solid-svg-icons'

import logo from '../../assets/imgs/logo.png'
import { useHistory } from "react-router-dom";
import { CART, CONTACT, HOME, PROFILE, toCategory } from '../../helper/paths';
import { default as Drawer } from '../drawer/Drawer';
import { default as DrawerItem } from '../drawer/DrawerItem';
import { DrawerBlock } from '../drawer/drawer.style';

const Index = ({ webInfo, auth, logout, cart }) => {

    const [isDisplayedDrawer, setIsDisplayedDrawer] = useState(false);

    const handleDisplayDrawer = () => {
        setIsDisplayedDrawer(!isDisplayedDrawer);
    }

    const history = useHistory();

    const queryParams = new URLSearchParams(window.location.search)
    const query = queryParams.get("query")
    const [search, setSearch] = useState(query ?? '')

    const handleSearch = (e) => {
        e.preventDefault();
        history.push('/search?query=' + search);
        setIsDisplayedDrawer(false);
    }
    const handleInputSearch = (e) => {
        setSearch(e.target.value);
    }

    return <Navbar className="my-navbar">
        <div className="container">
            <div className="lap-nav d-md-block d-none">
                <Row>
                    <Col md="2" style={{ position: 'relative' }}>
                        <div className="logo-wrapper">
                            <NavLink to="/"><img src={logo} alt="" /></NavLink>
                        </div>
                    </Col>
                    <Col md="10">

                        <div className="nav-wrapper">

                            <div>
                                <form className="search-wrapper" onSubmit={handleSearch}>
                                    <span className="rhombus"> <FontAwesomeIcon icon={faSearch} className="icon" /></span>
                                    <input type="text" onChange={handleInputSearch} value={search} name="search" required placeholder="Search For Products" />
                                    <span className="rhombus"></span>
                                </form>
                            </div>

                            <div className="links-wrapper">
                                <div className='d-flex' style={{ gap: '5px' }}>
                                    {
                                        auth.isAuthenticated ?
                                            <div className="link-item has-menu" >
                                                <DropdownLink><FontAwesomeIcon icon={faUser} className="icon" /><span>{auth.user.email}</span></DropdownLink>
                                                <Dropdown className="menu">
                                                    <DropdownItem >
                                                        <Link to={PROFILE} className="d-flex w-100" style={{ gap: '10px' }}>
                                                            <FontAwesomeIcon icon={faUser} />
                                                            <span>Profile</span>
                                                        </Link>
                                                    </DropdownItem>
                                                    {
                                                        /*
                                                        <DropdownItem>
                                                        <FontAwesomeIcon icon={faKey} />
                                                        <span>Reset Password</span>
                                                        </DropdownItem>
                                                        */
                                                    }

                                                    <DropdownItem onClick={logout}>
                                                        <FontAwesomeIcon icon={faSignOutAlt} />
                                                        <span>Logout</span>
                                                    </DropdownItem>
                                                </Dropdown>
                                            </div> :
                                            <>
                                                <Link to="/login" className="link-item">
                                                    <FontAwesomeIcon icon={faUser} className="icon" /><span>Login</span>
                                                </Link>
                                                <Link to="/register" className="link-item">
                                                    <span>/ Register</span>
                                                </Link>
                                            </>
                                    }

                                </div>

                                <Link to="/cart" className="link-item">
                                    <FontAwesomeIcon icon={faCartPlus} className="icon" /> <span>Cart </span> <span> ({cart.items.length})</span>
                                </Link>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>

            <div className="mobile-nav d-md-none">
                <div className="nav-wrapper">
                    <div className="">
                        <div className="logo-wrapper">
                            <NavLink to="/"><img src={logo} alt=""/></NavLink>
                        </div>
                    </div>
                    <div className="">
                        <div className="mobile-btns">
                            <Link className='btn' to={CART}>
                                <FontAwesomeIcon icon={faCartPlus} className="icon" />
                            </Link>
                            <button className="btn drawer-toggle" onClick={handleDisplayDrawer}>
                                <FontAwesomeIcon icon={faList} className="icon" />
                            </button>
                        </div>
                    </div>
                </div>

                <Drawer isDisplayed={isDisplayedDrawer} setIsDisplayedDrawer={setIsDisplayedDrawer}>

                    <div className="">

                        <form className="search-wrapper" onSubmit={handleSearch}>
                            <input type="text" onChange={handleInputSearch} value={search} name="search" required placeholder="Search For Products" />
                        </form>
                    </div>
                    <DrawerBlock onClick={() => setIsDisplayedDrawer(false)} >
                        <DrawerItem icon={<FontAwesomeIcon icon={faCartPlus} />}>
                            <Link to={CART}>
                                <span>Cart</span> <span> ({cart.items.length})</span>
                            </Link>
                        </DrawerItem>
                        {
                            auth.isAuthenticated ?
                                <>
                                    <DrawerItem icon={<FontAwesomeIcon icon={faUser} />}>
                                        <Link to={PROFILE}>
                                            <span>Profile</span>
                                        </Link>
                                    </DrawerItem>
                                    <DrawerItem icon={<FontAwesomeIcon icon={faKey} />}>
                                        <Link to={PROFILE} >
                                            <span>Reset Password</span>
                                        </Link>
                                    </DrawerItem>
                                    <DrawerItem icon={<FontAwesomeIcon icon={faSignOutAlt} onClick={logout} />}>
                                        <span>Logout</span>
                                    </DrawerItem>

                                </>
                                : <>
                                    <DrawerItem icon={<FontAwesomeIcon icon={faUser} />}>
                                        <Link to="/login"  >
                                            <span>Login</span>
                                        </Link>
                                    </DrawerItem>
                                    <DrawerItem icon={<FontAwesomeIcon icon={faUser} />}>
                                        <Link to="/register"  >
                                            <span>Register</span>
                                        </Link>
                                    </DrawerItem>
                                </>
                        }
                    </DrawerBlock>
                    <DrawerBlock onClick={() => setIsDisplayedDrawer(false)} >
                        <DrawerItem >
                            <Link to={HOME}  >
                                <span>Home</span>
                            </Link>
                        </DrawerItem>
                        {
                            webInfo.categories ?
                                webInfo.categories.map((category, index) =>
                                    <DrawerItem >
                                        <Link to={toCategory(category.id)}  >
                                            <span>{category.title}</span>
                                        </Link>
                                    </DrawerItem>
                                ) : ''
                        }
                        <DrawerItem >
                            <Link to={CONTACT}  >
                                <span>Contact Us</span>
                            </Link>
                        </DrawerItem>
                    </DrawerBlock>
                </Drawer>

            </div>


        </div >
    </Navbar >
}

function mapStateToProps(state) {
    const { webInfo, auth, cart } = state;
    return { webInfo, auth, cart };
}


export default connect(mapStateToProps, null)(Index);
