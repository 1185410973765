
import axios from "axios";
import { getToken } from "./cookies";

const domain = 'http://admin.perfume-testers.com/api/';
// const domain = 'http://localhost:8000/api/';

//webinfo
export const GET_WEB_INFO = domain + "webinfos";
//auth
export const LOGIN = domain + "auth/login";
export const REGISTER = domain + "auth/register";
export const ME = domain + "auth/me";
export const LOGOUT = domain + "auth/logout";
//slider
export const GET_SLIDERS = domain + "sliders";
//about
export const GET_ABOUT = domain + "about";
//services
export const GET_SERVICES = domain + "services";
//brands   
export const GET_BRANDS = domain + "brands";
export const GET_BRAND_PRODUCTS = domain + "brands";
//user
export const GET_PROFILE = domain + "get_my_profile";
export const GET_USER = domain + "profile/get_user_profile";
//products
export const GET_ALL_PRODUCTS = domain + "products";
export const GET_NEW_PRODUCTS = domain + "new_products";
export const GET_TOP_SELLS_PRODUCTS = domain + "top_sells_products";
export const GET_PRODUCT = domain + "products";
export const REVIEW_PRODUCT = domain + "review_product";
export const RATING_PRODUCT = domain + "rating_product";
export const GET_COUPON = domain + "get_coupon";

//category
export const GET_CATEGORY_PRODUCTS = domain + "categories";
//filter products
export const FILTER_PRODUCTS = domain + "filter";
export const SORT_PRODUCTS = domain + "sort";
//cart
export const CHECK_OUT_USER = domain + "checkout_user";
export const CHECK_OUT_GUEST = domain + "checkout_guest";

//profile
export const UPDATE_PROFILE_INFO = domain + "profile/update_info";
export const UPDATE_PROFILE_IMAGES = domain + "profile/update_images";

export const GET_SEARCH = domain + "search";
//contact
export const CONTACT = domain + "contact";


const getDataProtected = (url) => {
    return axios.get(url,
        {
            headers: {
                Authorization: "Bearer " + getToken()
            },
        })
        .then(x => x.data);
}

const getDataUnProtected = (url) => {
    return axios.get(url)
        .then(x => x.data);
}

const postDataProtected = (url, data) => {
    return axios.post(url, data,
        {
            headers: {
                Authorization: "Bearer " + getToken()
            },
        })
        .then(x => x.data);
}
const postDataUnProtected = (url, data) => {
    return axios.post(url, data)
        .then(x => x.data);
}



export const getWebInfoReq = () => {
    return getDataUnProtected(GET_WEB_INFO);
}

export const registerReq = (data) => {
    return axios.post(REGISTER, data)
        .then(x => x.data);
}

export const loginReq = (data) => {
    return axios.post(LOGIN, data)
        .then(x => x.data);
}


export const logoutReq = () => {
    return getDataProtected(LOGOUT);
}

export const meReq = () => {
    return getDataProtected(ME);
}


export const getSlidersReq = () => {
    return getDataUnProtected(GET_SLIDERS);
}

export const getAboutReq = () => {
    return getDataUnProtected(GET_ABOUT);
}

export const getServicesReq = () => {
    return getDataUnProtected(GET_SERVICES);
}

export const getBrandsReq = () => {
    return getDataUnProtected(GET_BRANDS);
}

export const getProfileReq = () => {
    return getDataProtected(GET_PROFILE);
}

export const getUserReq = (id) => {
    return getDataProtected(GET_USER + "/" + id);
}


export const getProductsReq = () => {
    return getDataUnProtected(GET_ALL_PRODUCTS);
}
export const getProductReq = (id) => {
    return getDataUnProtected(GET_PRODUCT + '/' + id);
}

export const getNewProductsReq = () => {
    return getDataUnProtected(GET_NEW_PRODUCTS);
}

export const getTopSellsProductsReq = () => {
    return getDataUnProtected(GET_TOP_SELLS_PRODUCTS);
}

export const getCategoryProductsReq = (id) => {
    return getDataUnProtected(GET_CATEGORY_PRODUCTS + '/' + id);
}
export const getBrandProductsReq = (id) => {
    return getDataUnProtected(GET_BRAND_PRODUCTS + '/' + id);
}
export const updatePersonalInfoReq = (data) => {
    return postDataProtected(UPDATE_PROFILE_INFO, data);
}

export const updatePersonalImagesReq = (data) => {
    return postDataProtected(UPDATE_PROFILE_IMAGES, data);
}

export const getSearchReq = (query) => {
    return getDataUnProtected(GET_SEARCH + "/" + query);
}

export const checkOutUserReq = (data) => {
    return postDataProtected(CHECK_OUT_USER, {
        ...data
    });
}

export const checkOutGuestReq = (data) => {
    return postDataUnProtected(CHECK_OUT_GUEST, {
        ...data
    });
}

export const contactReq = (data) => {
    return postDataUnProtected(CONTACT, { ...data });
}
export const filterProductsReq = (data) => {
    return postDataUnProtected(FILTER_PRODUCTS, { ...data });
}

export const sortProductsReq = (data) => {
    return postDataUnProtected(SORT_PRODUCTS, { ...data });
}

export const reviewProductReq = (id, data) => {
    return postDataUnProtected(REVIEW_PRODUCT + "/" + id, { ...data });
}
export const ratingProductReq = (id, data) => {
    return postDataUnProtected(RATING_PRODUCT + "/" + id, { ...data });
}
export const getCouponReq = (data) => {
    return postDataUnProtected(GET_COUPON , { ...data });
}