
import { CategoryItem } from "./style";
import { Link } from "react-router-dom";
const Index = ({ item, ...props }) => {

    return <Link to={"categories/" + item.id}>
        <CategoryItem {...props}>
            <h3 className="category-item-title">{item.title}</h3>
            <img className="category-item-img" src={item.image_thumb_path} />
        </CategoryItem >
    </Link>

}

export default Index