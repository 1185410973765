
import styled from 'styled-components';


export const CategoryItem = styled.a`
    display: block;
    text-align: center;
    margin-bottom: 45px;
    position: relative;
    padding: 45px 20px 20px;
    border:1px solid #a9a9a9;
    &:hover {
        border-color:var(--main-color)
    }
    img{
        transition:.3s;
    }
    .category-item-title{
        padding: 10px 20px ;
        text-align: center;
        font-size: 18px;
        font-family: LucidaFaxBoldItalic;
        margin: 0;
        position: absolute;
        top: -22px;
        left: -7px;
        max-width: max-content;
        z-index: 5;
        background:#fff;
        background:var(--main-color);
        transition:.2s;
    }
    &:hover .category-item-title{
        top: -35px;
        box-shadow:0  1px 5px 3px #ddd;
    }
    img:hover{
        transform: translateY(-10px);
    }
    .product-status{
        position: absolute;
        top: 0;
        left: 0;
        max-width: max-content;
        padding: 5px 10px;
        z-index: 5;
    }
    .product-status.status-out{
        font-size:13px;
        background: #0009;
        color: #fff;
        transform: rotateZ(-45deg);
        top: 15px;
        left: -40px;
        padding: 5px 40px;
    }
`;