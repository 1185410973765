

import React, { useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { default as LoadingPage } from '../components/loading/LoadingPage';
import { default as CartItem } from '../components/cart/Item';
import { default as SendingBox } from '../components/loading/SendingBox'

import { checkOutUserReq, checkOutGuestReq } from "../api/requests";
import { default as TotalCostBox } from '../components/total-cost-box/TotalCostBox';
import { checkout } from "../redux/cart";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CART, HOME, SHIPPMENT } from "../helper/paths";
import { CardBox, CardBoxItem } from "../components/cart/style";
import { Button } from "react-bootstrap";



const Index = ({ cart, auth, checkOutDispatch }) => {


    const [isLoading, setIsLoading] = useState(false);
    const [errorsRes, setErrorsRes] = useState({});
    const [isSending, setIsSending] = useState(false);

    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const handleCheckOut = () => {
        const { mobile, address, fullname, email, coupon } = cart.shipping;
        setError('');
        setIsSending(true);
        setErrorsRes({});
        if (auth.isAuthenticated) {
            checkOutUserReq({
                'items': cart.items,
                mobile, address, coupon_id: coupon.id
            }).then(res => {
                setIsSending(false);
                if (res.status) {
                    checkOutDispatch();
                    setMessage(res.message);
                    setError('');
                } else {
                    setError(res.message);
                    setErrorsRes(res.errors)
                }
            }).catch(e => {
                setIsSending(false);
            });
        }
        else {
            checkOutGuestReq({
                'items': cart.items,
                mobile, address, fullname, email, coupon_id: coupon.id
            }).then(res => {
                setIsSending(false);
                if (res.status) {
                    checkOutDispatch();
                    setMessage(res.message);
                    setError('');
                } else {
                    setError(res.message);
                }
            }).catch(e => {
                setIsSending(false);
            });
        }


    }

    return <div className="page checkout-page">
        <Container>
            {
                isLoading ?
                    <LoadingPage />
                    : <>
                        {
                            isSending ? <SendingBox /> : ''
                        }
                        {
                            message ?
                                <Alert variant="success" className="text-center"> {message} </Alert>
                                : ''
                        }
                        {

                            cart.items.length > 0 ?

                                <Row>
                                    <Col md="7">

                                        <CardBox >
                                            <div className="card-head">
                                                <div className="form-block-title">Shipping Information</div>
                                            </div>
                                            <div className="pt-4 mt-1 mb-3">
                                                {
                                                    !auth.isAuthenticated ?
                                                        <>
                                                            <CardBoxItem >
                                                                <div className="card-item-row">
                                                                    <label>Email :</label>
                                                                    <div>{cart.shipping.email}</div>
                                                                </div>
                                                                <small className="input-error">{errorsRes.email}</small>
                                                            </CardBoxItem>

                                                            <CardBoxItem >
                                                                <div className="card-item-row">
                                                                    <label>FullName :</label>
                                                                    <div>{cart.shipping.fullname}</div>
                                                                </div>
                                                                <small className="input-error">{errorsRes.fullname}</small>
                                                            </CardBoxItem>

                                                        </>
                                                        : ''
                                                }
                                                <CardBoxItem error={errorsRes.mobile}>
                                                    <div className="card-item-row">
                                                        <label>Mobile :</label>
                                                        <div>{cart.shipping.mobile}</div>
                                                    </div>
                                                    <small className="input-error">{errorsRes.mobile}</small>
                                                </CardBoxItem>
                                                <CardBoxItem error={errorsRes.address}>
                                                    <div className="card-item-row">
                                                        <label>Address :</label>
                                                        <div>{cart.shipping.address}</div>
                                                    </div>
                                                    <small className="input-error">{errorsRes.address}</small>
                                                </CardBoxItem>
                                                {
                                                    /*cart.shipping.coupon ?
                                                        <CardBoxItem error={errorsRes.coupon}>
                                                            <div className="card-item-row">
                                                                <label>Coupon :</label>
                                                                <div>{cart.shipping.coupon.code}</div>
                                                            </div>
                                                            <small className="input-error">{errorsRes.coupon}</small>
                                                        </CardBoxItem> : ''*/
                                                }


                                            </div>



                                            <div className="text-error  text-center my-3">
                                                {error}
                                            </div>
                                            <div className="btns-wrapper">
                                                <Button onClick={handleCheckOut}  >Send Order</Button>
                                            </div>
                                            <div className="links-wrapper">
                                                <Link to={CART} >  Return to cart</Link>
                                                <Link to={SHIPPMENT} >  Return to shipmment</Link>
                                            </div>
                                        </CardBox>

                                    </Col>
                                    <Col md="5">

                                        {

                                            cart.items.map((item, index) =>
                                                <div key={index}>
                                                    <CartItem item={item} editable={false} />
                                                </div>
                                            )

                                        }
                                        <TotalCostBox total_cost={cart.total_cost} currency={cart.currency} discount={cart.shipping.coupon.type ? cart.shipping.coupon : 0}/>

                                    </Col>

                                </Row>
                                : <div className="text-center mt-4" ><Link style={{ background: '#f2f2f2', padding: '15px 100px', margin: 'auto', width: 'max-content' }} to={HOME}> Return To Home </Link> </div>

                        }
                    </>
            }
        </Container>
    </div>
}


function mapStateToProps(state) {
    const { cart, auth } = state;
    return { cart, auth };
}
const mapDispatchToProps = dispatch => {
    return {
        checkOutDispatch: () => dispatch(checkout())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);