 
export const ACTIONS = {
    ADD_RATING: "ADD_RATING",
    REMOVE_RATING: "REMOVE_RATING"
};


export function addRating(rating) { return { type: ACTIONS.ADD_RATING, payload: rating } }
export function removeRating(id) { return { type: ACTIONS.REMOVE_RATING, payload: id } }

const ratings = []

const initialState = localStorage.getItem('ratings') ?
    [...JSON.parse(localStorage.getItem('ratings'))]
    : ratings;

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.ADD_RATING: {
            let id = action.payload.product_id;
            let rate = action.payload.rate;
            let items = state;
            items.push({ product_id: id, rate });

            localStorage.setItem('ratings', JSON.stringify(items));

            return [...ratings];
        }

        case ACTIONS.REMOVE_ITEM: {
            let id = action.payload;
            let items = state;
            items = state.filter(item => item.id !== id);
            localStorage.setItem('ratings', JSON.stringify(items))

            return [...ratings];
        }

        default:
            return state;
    }
};