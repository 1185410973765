import { Container  } from 'react-bootstrap';

import Pulse from 'react-reveal/Pulse';
import { Banner } from "./style";
import { OverlayBox } from "../style";

const Index = ({ banner }) => {

    return <Banner className="banner-section overlay-wrapper" style={{ backgroundImage: `url('${banner.image_path}')` }} >


        {
            banner ?
                <OverlayBox bg="var(--bg-opacity-color)" style={{ padding: '20px' }}>
                    <Pulse top>


                        <Container>


                            <div>
                                <h3 className="banner-title">{banner.title}</h3>
                                <div className="banner-desc" dangerouslySetInnerHTML={{ __html: banner.description }} />
                                {
                                    banner.link ?
                                        <a className="btn btn-primary" href={banner.link} >Show Details</a>
                                        : ''
                                }
                            </div>
                        </Container>
                    </Pulse>
                </OverlayBox>
                : ''
        }

    </Banner >
}

export default Index