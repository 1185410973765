import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = ({
    component: Component,
    login: login,
    ...rest
}) => {
    return (
        <Route
            {...rest}
            render={(props) => <Component login={login}  {...rest} />}
        />

    )
}
export default PublicRoute;