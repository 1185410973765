
 import { Alert } from "./style";
import { Link } from 'react-router-dom';
import { CART } from '../../helper/paths'
import { Button } from 'react-bootstrap';

const Index = ({ alert, item }) => {

    return <Alert className="overlay-wrapper"  >
        {
            alert ?
                <div>
                    <div className=' '>
                        <div className='alert-row'>
                            <label>Total Cost :</label> <span className='value'> {item.total_cost} </span>
                        </div>
                        <div className='alert-row'>
                            <label>Quantity : </label> <span className='value'> {item.count}</span>
                        </div>
                        <div className='alert-row'>
                            <label>Product : </label> <span className='value'> {item.title}</span>
                        </div>

                    </div>
                    <div className='alert-btns'>
                        <Button >Keep Shopping</Button>
                        <Link className='btn' to={CART}>Cart</Link>
                    </div>
                </div>

                : ''
        }

    </Alert >

}

export default Index