 import { Container } from 'react-bootstrap';
import { default as SectionHead } from '../section-head/Index';
import { default as Brand } from './Item';
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
    Navigation, Autoplay
} from 'swiper';
import React from "react";


SwiperCore.use([Navigation, Autoplay]);


const Index = ({ brands, title }) => {



    return <div className="brands-sliders-section section">
        <Container>
            {
                title !== '' ?
                    <SectionHead title={title} className="text-center mx-auto"></SectionHead>
                    : ''
            }
            <Swiper
                style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }}
                spaceBetween={20}
                navigation={false}
                className="product-slider"
                watchSlidesProgress
                autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    360: {
                        slidesPerView: 2,
                    },
                    640: {
                        slidesPerView: 3,
                        // spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 6,
                        //spaceBetween: 40,
                    }
                }}
            >
                {
                    brands.map((item, index) =>
                        <SwiperSlide key={index} >
                            <Brand item={item} />
                        </SwiperSlide>
                    )
                }
            </Swiper>

        </Container>
    </div >
}


export default Index;
