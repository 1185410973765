

import React, { useEffect, useState } from "react";
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { toast } from 'react-toastify';
import Lightbox from 'react-image-lightbox';
import { Helmet } from "react-helmet";

import { default as LoadingPage } from '../components/loading/LoadingPage';
import { default as Alert } from '../components/alert/Index';
import { default as ShareBtns } from '../components/share-btns/Index';
import { default as Rating } from '../components/rating/Index';
import { default as ProductInfoItem } from '../components/product-info-item/Index';
import { connect } from 'react-redux';

import { useParams } from "react-router-dom";
import { getProductReq, ratingProductReq, reviewProductReq } from "../api/requests";
//import { ProductItem } from "../components/style";
import { Button } from "react-bootstrap";
import { addItem } from "../redux/cart";
import { addRating } from "../redux/ratings";
import NoProfileImg from '../assets/imgs/no-profile-img.png'

const Index = ({ addRating, addItem, cart, webInfo, auth, ratings }) => {

    const { id } = useParams();

    const keywords = webInfo.categories.reduce((result, item) => {
        return result + ", " + item.title;
    }, "");

    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState({});
    const [images, setImages] = useState([]);
    const [index, setIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(0);
    const [count, setCount] = useState(1);
    const [sizeInx, setSizeInx] = useState(0);
    const [errorSentReview, setErrorSentReview] = useState('');
    const [isRated, setIsRated] = useState(false);
    const [rating, setRating] = useState(0);
    const [reviews, setReviews] = useState([]);
    const [newReview, setNewReview] = useState({
        full_name: auth.user.id ? auth.user.full_name : '',
        review: ''
    });

    const [reviewMessage, setReviewMessage] = useState('');

    const increase = () => {
        if (count < product.in_stock) {
            var cnt = count + 1;
            setCount(cnt);
        }
    }
    const decrease = () => {
        if (count > 1) {
            var cnt = count - 1;
            setCount(cnt);
        }
    }
    const handleSetSize = (inx) => {
        setSizeInx(inx)
    }
    const addToCart = () => {
        let productsInCart = cart.items.filter(item => parseInt(item.id) === product.id);
        let countInCart = productsInCart.length > 0 ? productsInCart[0].count : 0;
        if (countInCart + count <= product.in_stock) {
            addItem({
                ...product,
                count,
                size: product.sizes[sizeInx],
                total_cost: parseInt(count) * parseInt(product.price)
            });
            toast(<Alert item={{
                ...product,
                count,
                size: product.sizes[sizeInx],
                total_cost: parseInt(count) * parseInt(product.price)
            }} alert={{}} />);
        }
        else {
            toast(<div>This quantity of <span style={{ color: 'var(--main-color)', fontWeight: 'bold' }}>{product.title}</span> is not available in the stock</div>);
        }
    }

    const handleChangeInput = (e) => {
        setNewReview({
            ...newReview,
            [e.target.name]: e.target.value
        })
    }

    const handleRating = (rate) => {
        setRating(rate);
        ratingProductReq(product.id, { rating: rate }).then(res => {
            if (res.status) {
                setIsRated(true);
                setProduct({ ...product, ratings: ratings + 1 })
                toast(<div>You have Rated This Product With <span style={{ color: 'var(--main-color)', fontWeight: 'bold' }}>{rate}</span>  Successfully</div>);
                addRating({ product_id: product.id, rate })
            } else {
                toast(<div> <span style={{ color: 'red', fontWeight: 'bold' }}>You have not Rated Successfully</span> </div>);
            }
        }).catch(e => {
            toast(<div> <span style={{ color: 'red', fontWeight: 'bold' }}>You have not Rated Successfully</span> </div>);
        });
    }

    const handleReview = (e) => {
        e.preventDefault();
        setErrorSentReview('')
        reviewProductReq(product.id, { ...newReview }).then(res => {
            if (res.status) {
                //setReviews([newReview, ...reviews]);
                setReviewMessage(res.message)
                setNewReview({
                    full_name: auth.user.id ? auth.user.full_name : '',
                    review: ''
                })
            } else {
                setErrorSentReview(res.message);
            }
        }).catch(e => {
            setIsLoading(false);
            setErrorSentReview('Sent Failed');
        });
    }
    useEffect(() => {
        setIsLoading(true)
        getProductReq(id).then(res => {
            setIsLoading(false);
            if (res.status) {
                setProduct(res.item);
                setReviews(res.item.reviews);
                setImages([res.item, ...res.item.images]);
                let ratings_temp = ratings.filter(item => item.product_id === res.item.id);
                if (ratings_temp.length > 0) {
                    setIsRated(true);
                    setRating(ratings_temp[0].rate)
                }
                //setDocumentTitle(webInfo.site_name, res.item.brand.title, res.item.title, res.item.image_thumb_path, res.item.brand.title + "," + res.item.title)
            } else {
                //setError(res.message);
            }
        }).catch(e => {
            setIsLoading(false);

        });
    }, [id])



    return <div className="page product-page">
        <Container>
            {
                isLoading ?
                    <LoadingPage /> :
                    <>
                        <Helmet>
                            <title>{webInfo.site_name} | {product.brand ? product.brand.title : ''} </title>
                            <meta name="description" content={product.title} />
                            <meta name="keywords" content={`${product.title} , ${product.brand ? product.brand.title : ''} ${keywords}`} />
                            <meta name="author" content="Provided by Swan Technology" />
                            <meta property="og:title" content={product.title} />
                            <meta property="og:type" content="article" />
                            <meta property="og:url" content={window.location.href} />
                            <meta property="og:image" content={product.image_path} />
                            <meta name="twitter:title" content={product.title} />
                            <meta name="twitter:description" content={product.title} />
                            <meta name="twitter:image" content={product.image_path}></meta>

                        </Helmet>
                        <div className="pb-4">
                            <Row>
                                <Col md="5">
                                    <Fade left>
                                        <div className="img-wrapper" onClick={() => setIsOpen(true)}>
                                            <img src={images[index] ? images[index].image_path : ''} alt="" />
                                        </div>
                                        <div className="images-other">
                                            {
                                                images.map((img, ind) =>
                                                    <div key={ind} className={(ind === index) ? "active other-image-wrapper" : 'other-image-wrapper'} onClick={() => setIndex(ind)}><img alt="" src={img.image_path} /></div>
                                                )
                                            }
                                        </div>
                                    </Fade>
                                    <Fade bottom>
                                        <ShareBtns title={product.title} description={product.description} />
                                    </Fade>
                                </Col>
                                <Col md="7">
                                    <Fade right>
                                        <div className="product-details">
                                            <h1 className="product-title">{product.title}</h1>
                                            <div className="d-flex justify-content-between align-items-center">

                                                <div className="my-1">
                                                    <div className="product-price">{product.price_currency} </div>
                                                </div>
                                                <div className="product-rating" >
                                                    <Rating
                                                        val={rating}
                                                        bgSymbol="var(--main-color)"
                                                        emSymbol="#aaa"
                                                        style={{ fontSize: '20px' }}
                                                        onChange={(rate) => handleRating(rate)}
                                                        readonly={isRated ? true : null}
                                                    />
                                                    <div>( {product.ratings ? product.ratings.length : 0} ) Ratings </div>
                                                </div>

                                            </div>

                                            <div className="product-info mt-3">

                                                <ProductInfoItem label="Category">
                                                    <span>{product.category ? product.category.title : ''}</span>
                                                </ProductInfoItem>
                                                <ProductInfoItem label="Gender">
                                                    <span>{product.product_type ? product.product_type.title : ''}</span>
                                                </ProductInfoItem>
                                                <ProductInfoItem label="Brand">
                                                    <span>{product.brand ? product.brand.title : ''}</span>
                                                </ProductInfoItem>

                                            </div>
                                            <p className="product-desc" dangerouslySetInnerHTML={{ __html: product.description }} />

                                            <div className="options-wrapper">
                                                <div className="option-item">
                                                    <div className="option-item--title">Size :</div>
                                                    <ul className="option-item-list list">
                                                        {
                                                            product.sizes ?
                                                                <>
                                                                    {
                                                                        product.available ?
                                                                            product.sizes.map((sz, index) =>
                                                                                <li key={index} className={index === sizeInx ? "active" : ''} style={{ cursor: 'pointer' }} onClick={() => handleSetSize(index)}>{sz.title}</li>
                                                                            ) :
                                                                            product.sizes.map((sz, index) =>
                                                                                <li key={index} >{sz.title}</li>
                                                                            )
                                                                    }
                                                                </>
                                                                : ''
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            {
                                                product.available ?
                                                    <div className="actions-wrapper">
                                                        <div className="quality-wrapper">
                                                            <span>Quality :</span>
                                                            <div className="count-btns-wrapper"><Button onClick={increase}><span>+</span></Button>
                                                                <span className="count">{count}</span>
                                                                <Button onClick={decrease} ><span>-</span></Button> </div>
                                                        </div>
                                                        <div className="action-btns">
                                                            <Button className="action-btn add-to-card" onClick={addToCart}>Add To Card</Button>
                                                        </div>
                                                    </div>
                                                    : <div className="py-4">This Item is not available now</div>
                                            }


                                        </div>
                                    </Fade>
                                </Col>
                            </Row>
                        </div>

                        <div className="reviews row" >
                            <div className="col-md-6">
                                <h3 className="reviews-title">Add Your Review</h3>
                                {
                                    reviewMessage != '' ?
                                        <div className="alert alert-success">
                                            {reviewMessage}
                                        </div> : ''
                                }

                                <form onSubmit={handleReview} className="mb-4">
                                    {
                                        auth.user.id === undefined ?
                                            <FormControl title="Please Enter Your Name Here" onChange={handleChangeInput} placeholder="Enter Your Name" name="full_name" value={newReview.full_name} required />
                                            : ''
                                    }
                                    <FormControl as="textarea" title="Please Enter Your Review Here" onChange={handleChangeInput} placeholder="Enter Your Review" name="review" value={newReview.review} required />

                                    {errorSentReview ?
                                        <div className="text-error  my-3">
                                            {errorSentReview}
                                        </div> : ''}
                                    <Button type="submit" className="btn-main">Send Your Review</Button>
                                </form>
                            </div>
                            <div className="col-md-12">
                                <h3 className="reviews-title">Reviews</h3>

                                {
                                    reviews.length > 0 ?
                                        reviews.map((review, index) =>
                                            <div className="review-item" key={index}>
                                                <div className="review-item-top">
                                                    {
                                                        auth.user.image_path ?
                                                            <img src={auth.user.image_path} alt="" />
                                                            : <img src={NoProfileImg} alt="" />
                                                    }
                                                    <h6>{review.full_name}</h6>
                                                </div>
                                                <div className="review-item-text">
                                                    {review.review}
                                                </div>
                                            </div>
                                        )
                                        : ''
                                }

                            </div>

                        </div>

                        {isOpen ?
                            <Lightbox
                                mainSrc={images[index].image_path}
                                nextSrc={images[(index + 1) % images.length]}
                                prevSrc={images[(index + images.length - 1) % images.length]}
                                onCloseRequest={() => setIsOpen(false)}
                                onMovePrevRequest={() =>
                                    setIndex((index + images.length - 1) % images.length)
                                }
                                onMoveNextRequest={() =>
                                    setIndex((index + 1) % images.length,
                                    )
                                }
                            />
                            : ''}


                    </>

            }
        </Container>
    </div>
}


function mapStateToProps(state) {
    const { webInfo, cart, auth, ratings } = state;
    return { webInfo, cart, auth, ratings };
}
const mapDispatchToProps = dispatch => {
    return {
        addItem: (item) => dispatch(addItem(item)),
        addRating: (rating) => dispatch(addRating(rating))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Index);
