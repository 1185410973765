
import styled from 'styled-components';


export const Alert = styled.div`
    display: block;
    background: #fff;
    color:#222; 
    padding: 10px ;
    width: 500px;
    .alert-row {
        display:flex;
        padding:3px 0;
    }
    .alert-row:last-child{
        margin-bottom :30px ;
    }
    .alert-row label{
        min-width: 90px;
        width: 90px;
    }
    .alert-row .value{
        color: var(--main-color);
        font-weight:bold;
        white-space: nowrap;
    }
    .alert-btns{
        display:flex;
        justify-content: end;
        gap: 10px;
    }
    .btn {
        color:#fff;
    }
    .btn:last-child{
        background: var(--main-color);
        border-color :var(--main-color)
    }
    .btn:first-child{
        background: var(--bg-color);
        border-color : var(--bg-color)
    }
    @media( max-width : 992px){
        width: 100%;
    }
`;