import { useRef, useState, useContext } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

import { faKey } from '@fortawesome/free-solid-svg-icons'

import FormInput from '../components/auth/Input'

import { AuthContext } from "../context/AuthContext";
import { default as AuthCard } from '../components/auth/AuthCard';

const Index = () => {

    const { state } = useContext(AuthContext);

    const passwordRef = useRef('');
    const confirmPasswordRef = useRef('');
    const [errors, setErrors] = useState({
        password: '',
        confirmPassword: ''
    })

    const validate = () => {
        let password = passwordRef.current.value;
        let confirmPassword = confirmPasswordRef.current.value;
        let errorsTemp = errors;
        let isValidated = true;


        if (password === '') {
            errorsTemp.password = 'كلمة المرور مطلوبة'
            isValidated = false
        }
        else if (password.length < 8) {
            errorsTemp.password = 'كلمة المرور يجب أن تكون أكبر من 8'
            isValidated = false
        }
        else {
            errorsTemp.password = ''
        }
        if (password !== confirmPassword) {
            errorsTemp.confirmPassword = 'غير متطابق مع كلمة المرور'
            isValidated = false
        }
        else {
            errorsTemp.confirmPassword = ''
        }
        setErrors({ ...errorsTemp });
        return isValidated;

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValidated = validate();
    }

    if (state.isAuthenticated)
        return <Redirect to="/" />

    return <div className="page auth-page overlay-wrapper">
        <div className="overlay-box"> </div>
        <Container>
            <AuthCard title="تعديل كلمة المرور"  >
                <Form onSubmit={handleSubmit}>
                    <FormInput icon={faKey} type="password" placeholder=" أدخل كلمة السر الجديدة" ref={passwordRef} error={errors.password} name="password" />
                    <FormInput icon={faKey} type="password" placeholder="أدخل تأكيد كلمة السر الجديدة" ref={confirmPasswordRef} error={errors.confirmPassword} name="confirmPassword" />

                    <Button className="btn-main" type="submit">
                        Submit
                    </Button>
                </Form>

            </AuthCard>
        </Container >
    </div >

}

export default Index
