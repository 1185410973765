
import styled from 'styled-components';


export const ProductInfoItem = styled.div`
    display: flex;
    padding-bottom:10px ;
    .label{
        width:80px;
        min-width:80px;
        font-weight:bold;
    }
   
`;