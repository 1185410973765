
import { ProductItem } from "../style"

import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import SwiperCore, {
    Navigation, Autoplay
} from 'swiper';
import React from "react";
import { Link } from "react-router-dom";
import { toProduct } from "../../helper/paths";

SwiperCore.use([Navigation, Autoplay]);

const Index = ({ item, ...props }) => {
    const swiperRef = React.useRef();
    const onInit = (SwiperCore) => {
        swiperRef.current = SwiperCore;
    };
    const handleMouseEnter = () => {
        if (swiperRef.current) swiperRef.current.autoplay.start();
    };
    const handleMouseLeave = () => {

        if (swiperRef.current) swiperRef.current.autoplay.stop();
    };

    return <Link to={toProduct(item.slug)}>
        <ProductItem className="new-product" {...props} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            {
                item.in_stock === 0 ? < div className="product-status status-out"><span>Out Of Stock</span></div>
                    : item.new === 1 ? <div className="product-status status-new"><span>New</span></div>
                        : ''
            }
            <Swiper
                onInit={onInit}
                ref={swiperRef}
                style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }}
                spaceBetween={0}
                navigation={false}
                className="product-slider"
                watchSlidesProgress
                slidesPerView={1}
            >
                {
                    item.images ?
                        [{ image_thumb_path: item.image_thumb_path }, ...item.images].map((img, index) =>
                            <SwiperSlide key={index} >
                                <div >
                                    <img src={img.image_thumb_path} alt=""/>
                                </div>
                            </SwiperSlide>
                        ) : ''
                }
            </Swiper>
            <div className="product-item-line"></div>
            <h3 className="product-item-title">{item.brand ? item.brand.title : ''}</h3>
            <div className="short-desc" dangerouslySetInnerHTML={{ __html: item.title }} />
            <div className="product-item-price">{item.price_currency}</div>
        </ProductItem >
    </Link>

}

export default Index