



import styled from 'styled-components';


export const Orders = styled.div`
    padding-top: 10px;
    border:1px solid #d3d3d3;
    .title{
        font-size:18px;
        font-weight:bold;
        margin-top:-20px;
        margin-bottom: 25px;
        background:#fff;
        width:max-content;
        padding:0 5px;
        padding-inline-end:   20px
    }
`;

export const Order = styled.div`
    padding: 10px;
    &:nth-child(odd){
        background : #f2f2f2;
    }
   
    .title{
        font-size:18px;
        font-weight:bold;
        margin-top:-20px;
        margin-inline-start:-12px;
        background:#fff;
        width:max-content;
        padding:0 5px;
        padding-inline-end:   20px
    }
    .order-row{
        display:flex;
        gap:15px;
        justify-content:space-between;
        padding-bottom:20px;
        font-weight: bold;
    }
    .order-row-info{
        display:flex;
        gap:15px;
    }
    .order-item-row{
        display:flex;
        gap:15px;
        padding-bottom: 7px;
        color:#636363;
        position:relative;
        padding-inline-start:15px;
    }
    .order-item-row::before{
        content:'';
        display:block;
        width:5px;
        height:5px;
        position:absolute;
        left:0;
        top:30%;
        background:#636363;
    }
    
    @media(max-width : 992px){
        .order-row{
            display:block;
        }
        .order-item-row{
            display:block;
        }
        
        .order-item-row::before{
            top: 7px;
        }
    }
`;
