import { useRef, useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import { faClipboard, faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons'
import { faBullseye, faFax, faMapMarked, faMobile, faPhone } from '@fortawesome/free-solid-svg-icons'
import FormInput from '../components/auth/Input'
import { default as AuthCard } from '../components/auth/AuthCard'
import { default as ContactBox } from '../components/contact-box/Index'
import { default as PageTitle } from '../components/page-title/Index'
import { default as SendingBox } from '../components/loading/SendingBox'
import { contactReq } from '../api/requests'
import { setDocumentTitle, validateContact } from '../helper/methods';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

const Index = ({ webInfo }) => {


    const [isSending, setIsSending] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [errorsRes, setErrorsRes] = useState({});

    const emailRef = useRef('');
    const nameRef = useRef('');
    const subjectRef = useRef('');
    const messageRef = useRef('');
    const [errors, setErrors] = useState({
        email: '',
        name: '',
        subject: '',
        message: ''
    })

    const validate = () => {
        let email = emailRef.current.value;
        let name = nameRef.current.value;
        let subject = subjectRef.current.value;
        let message = messageRef.current.value;
        let res = validateContact(email, name, subject, message, errors);
        setErrors({ ...res.errorsTemp });
        return res.isValidated;
    }
    const handleContact = (e) => {
        e.preventDefault();
        let isValidated = validate();
        setError('');
        setMessage('');
        setErrorsRes({})
        if (isValidated) {
            setIsSending(true)
            contactReq({
                'email': emailRef.current.value,
                'name': nameRef.current.value,
                'subject': subjectRef.current.value,
                'message': messageRef.current.value,
            }).then(res => {
                //console.log(res);
                setIsSending(false);
                if (res.status) {
                    toast.success(res.message);
                    emailRef.current.value = "";
                    nameRef.current.value = "";
                    subjectRef.current.value = "";
                    messageRef.current.value = "";

                } else {
                    setErrorsRes(res.errors);
                }
            }).catch(e => {
                setIsSending(false);
            });
        }
    }
    useEffect(() => {
        setDocumentTitle(webInfo.site_name, "Contact US");
    }, []);

    return <div className="page overlay-wrapper bg-grey pb-0">
        <Container>
            <PageTitle title={webInfo.contact.title} className="pt-4 mt-1"></PageTitle>
            <Row>
                <Col md={6}>
                    <div  >
                        {
                            webInfo.contact.email ?
                                <ContactBox icon={faEnvelope}  >
                                    <a href={"mailto:" + webInfo.contact.email}>{webInfo.contact.email}</a>
                                </ContactBox> : ''
                        }
                        {
                            webInfo.contact.address ?
                                <ContactBox icon={faMapMarked}  >
                                    {webInfo.contact.address}
                                </ContactBox> : ''
                        }
                        {
                            webInfo.contact.phone ?
                                <ContactBox icon={faPhone}  >
                                    <a href={"tel:" + webInfo.contact.phone}>{webInfo.contact.phone}</a>
                                </ContactBox> : ''
                        }
                        {
                            webInfo.contact.mobile ?
                                <ContactBox icon={faMobile}  >
                                    <a href={"tel:" + webInfo.contact.mobile}>{webInfo.contact.mobile}</a>
                                </ContactBox> : ''
                        }
                        {
                            webInfo.contact.fax ?
                                <ContactBox icon={faFax}  >
                                    {webInfo.contact.fax}
                                </ContactBox> : ''
                        }
                    </div>

                </Col>
                <Col md={6}>
                    <AuthCard title="" wdth="auto">
                        {
                            isSending ? <SendingBox /> : ''
                        }
                        {
                            message ? <div className='text-success auth-message mb-2 pb-2 text-center'>{message}</div> : ''
                        }
                        <Form onSubmit={handleContact} >
                            <div className='pb-4'>
                                <FormInput icon={faEnvelope} type="email" placeholder="Enter Your Email" ref={emailRef} error={errors.email || errorsRes.email} name="email" />
                                <FormInput icon={faUser} type="text" placeholder="Enter Your Name" ref={nameRef} error={errors.name || errorsRes.name} name="name" />
                                <FormInput icon={faBullseye} type="text" placeholder="Enter Your Subject" ref={subjectRef} error={errors.subject || errorsRes.subject} name="subject" />
                                <FormInput icon={faClipboard} type="text" placeholder="Enter Your Message" as="textarea" ref={messageRef} error={errors.message || errorsRes.message} name="message" />

                            </div>

                            {
                                error ? <div className='auth-error'>{error}</div> : ''
                            }
                            <Button className="btn-main" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </AuthCard>

                </Col>
            </Row>

        </Container>
        <div id="map" style={{ marginTop: '50px' }} dangerouslySetInnerHTML={{ __html: webInfo.contact.map }} />
    </div>

}

function mapStateToProps(state) {
    const { webInfo } = state;
    return { webInfo };
}

export default connect(mapStateToProps, null)(Index);