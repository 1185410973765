
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react';
import { Form } from 'react-bootstrap';
import { InputWrapper } from './style';

const Index = React.forwardRef((props, ref) => {
    const { icon, placeholder, type, error, name } = props;
    return <Form.Group className="mb-3" controlId={"formBasic" + name}>
        <InputWrapper>
            <Form.Label className="input-icon">
                <FontAwesomeIcon icon={icon} />
            </Form.Label>
            <Form.Control {...props} type={type} placeholder={placeholder} ref={ref} />
        </InputWrapper>
        <Form.Text className="text-muted text-error input-error">
            {error}
        </Form.Text>
    </Form.Group>

});

export default Index