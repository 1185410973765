
//import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faWhatsapp, faInstagram } from '@fortawesome/free-brands-svg-icons'
//import { faCopyright } from '@fortawesome/free-solid-svg-icons'
//import { faCopyright } from '@fortawesome/free-regular-svg-icons'

import { ABOUT, BRANDS, CONTACT, HOME } from '../../helper/paths';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {  Footer } from './style';
import { connect } from 'react-redux';


const Index = ({ webInfo }) => {
    library.add(fab);


    return <Footer className="footer">
        <div className="container">
            <div className="footer-top">
                <div className="footer-top-wrapper">
                    <div className="">
                        <h3 className="footer-title">
                            {
                                webInfo.site_name
                            }
                        </h3>
                    </div>
                    <div className="social-wrapper">
                        <ul className="socialmedia-list list">
                            {
                                webInfo.socials.map((social, index) =>
                                    <li key={index}><a href={social.link} target="_blank"><FontAwesomeIcon icon={["fab", social.type]} className={" social-icon"} /></a></li>
                                )
                            }

                        </ul>
                    </div>
                    <div className="footer-links-wrapper">
                        <ul className="footer-links-list list">
                            <li><Link to={HOME}>Home</Link></li>
                            <li><Link to={ABOUT}>About</Link></li>
                            <li><Link to={BRANDS}>Brands</Link></li>
                            <li><Link to={CONTACT}>Contact Us</Link></li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
        <div className="footer-bottom">
            <div className="container">
                <div className="copyrights">
                    Powered by Swan Technology - all rights reserved {(new Date()).getFullYear()}
                </div>
            </div>
        </div>
    </Footer>

}

function mapStateToProps(state) {
    const { webInfo } = state;
    return { webInfo };
}

export default connect(mapStateToProps, null)(Index);