import { useRef, useState } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';

import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { connect } from 'react-redux';

import FormInput from '../components/auth/Input'

import { default as AuthCard } from '../components/auth/AuthCard';

const Index = ({ auth }) => {


    const emailRef = useRef('');
    const [errors, setErrors] = useState({
        email: ''
    })

    const validate = () => {
        let email = emailRef.current.value;
        let errorsTemp = errors;
        let isValidated = true;

        if (email === '') {
            errorsTemp.email = 'Email is required'
            isValidated = false
        }
        else {
            errorsTemp.email = ''
        }

        setErrors({ ...errorsTemp });
        return isValidated;

    }
    const handleSubmit = (e) => {
        e.preventDefault();
        let isValidated = validate();


    }
    /*
        const inputs = [
            { icon: { faEnvelope }, type: "email", placeholder: "أدخل بريدك الإلكتروني", ref: { emailRef }, name: "email" }
        ]
    */
    if (auth.isAuthenticated)
        return <Redirect to="/" />

    return <div className="page auth-page overlay-wrapper">
        <div className="overlay-box"> </div>
        <Container>
            <AuthCard title="Forget Password"  >
                <Form onSubmit={handleSubmit}>
                    <FormInput icon={faEnvelope} type="email" placeholder="Enter Your Email" ref={emailRef} error={errors.email} name="email" />
                    <div className='my-4'>
                        <small>
                            It will be send a message to the entered email
                        </small>
                    </div>

                    <Button className="btn-main" type="submit">
                        Submit
                    </Button>
                </Form>
                <div className="text-center mt-4">
                    <Link to="/login" >Login</Link>
                </div>
            </AuthCard>
        </Container >
    </div >

}

function mapStateToProps(state) {
    const { auth } = state;
    return { auth };
}
export default connect(mapStateToProps, null)(Index);
