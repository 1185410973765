


import { TotalCostBox } from './TotalCostBox.Style';

const Index = ({ total_cost, currency, discount, ...rest }) => {

    console.log(total_cost);
    return <TotalCostBox  {...rest}>

        <label className="total-cost-label">Total : </label>

        <div className="total-cost-value">
            {
                discount !== 0 && discount !== undefined ?
                    <>
                        <del>{total_cost} {currency}</del> &nbsp;
                        <ins>
                            {
                                discount.type === "percent" ? (total_cost - ((total_cost * discount.value) / 100)) : (total_cost - discount.value)
                            }
                            {currency}
                        </ins>
                    </>
                    : <>{total_cost} {currency}</>
            }

        </div>
    </TotalCostBox >
}

export default Index