import { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { default as SectionHead } from '../section-head/Index';
import Fade from 'react-reveal/Fade';
import { getTopSellsProductsReq } from '../../api/requests'
import { default as Item } from './Item';

const Index = ({ title }) => {
    const [products, setProducts] = useState([])
    
    useEffect(() => {
        getTopSellsProductsReq().then(res => {
            //setIsLoading(false);
            if (res.status) {
                setProducts(res.items);
            } else {
               // setError(res.message);
            }
        }).catch(e => {
           // setIsLoading(false);
        });

    }, []);
    return <div className="new-products-section products-section section bg-grey ">
        <Container>
            {
                title !== '' ?
                    <SectionHead title={title} className="text-center mx-auto"></SectionHead>
                    : ''
            }
            <Row>
                {
                    products.map((product, index) =>
                        <Col md={3} key={index} style={{ marginBottom: '30px' }}>
                            <Fade bottom style={{ height: '100%' }}>
                                <Item item={product} />
                            </Fade>
                        </Col>
                    )
                }
            </Row>

        </Container>
    </div >
}

export default Index