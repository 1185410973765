
import styled from 'styled-components';

export const Topbar = styled.div`
    background-color: #fff;
    padding: 10px 0;
    font-family: LucidaFaxItalic; 
    .topbar-wrapper{
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--bg-color);
        position: relative;
    }
    .socialmedia-list li:hover .icon{
        color:var(--main-color);
    }
    
    .contact-list{
        display: flex;
        padding: 0 5px;
        justify-content: space-between;
        gap: 10px;
        color: var(--bg-color);
    }
    .contact-list li:first-child{
        padding-inline-end: 25px;
    }
    
    .contact-item{
        white-space:nowrap;
    }
    .contact-item span,.contact-item a{
        font-size: 13px;
        font-family: Tahoma;
    }
    .contact-list li .icon{
        padding-inline-end: 7px;
        font-size: 23px;
    }
    
    @media (min-width:992px) {
        [class*="mobile-"]{
            display: none;
        }
    }
    @media (max-width:992px) {
        .search-wrapper{
            display: none;
        }
        .topbar-wrapper{
            display: block;
            padding: 5px 0;
        }
        .contact-list{
            padding: 0;
            padding-bottom: 15px;
            flex-wrap: wrap;
            justify-content: normal;
        }
    }
    @media (max-width:400px) {
        .contact-list {
            font-size:12px;
        }
    }

`;

export const Navbar = styled.div`
    background-color: var(--bg-color);
    width: 100%;
    z-index: 10000;
    color: #fff;
    padding: 20px 0;
    .logo-wrapper{
        position: absolute;
        top: calc(-100% - 20px);
    }
    .logo-wrapper img{
        width: 160px;
    }
    .my-navbar-wrapper{
        display: flex;
        gap:10px;
    }
    .navbar-wrapper{
        display: flex;
    }
    .nav-wrapper{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        gap: 50px;
        font-size: 14px;
    }
    .search-wrapper{
        position: relative;
    }
    .rhombus{
        border: 2px solid var(--main-color);
        width: 30px;
        height: 30px;
        background-color: var(--main-light-color);
        display: inline-block;
        transform: rotateZ(45deg);
        border-radius: 5px;
        line-height: 30px;
        text-align: center;
    }
    .search-wrapper .rhombus{
        position: absolute;
        top:4px;
        color: var(--main-color);
    }
    .search-wrapper .rhombus .icon{
        transform: rotateZ(45deg);
    }
    .search-wrapper .rhombus:first-child{
        left:-14px;
    }
    .search-wrapper .rhombus:last-child{
        right:-14px;
    }
    .search-wrapper input{
        border: 2px solid var(--main-color);
        padding: 2px 25px;
        background:none;
        background-color: #fff;
        color: var(--main-dark-color);
        height: 39px;
        border-radius: 5px;
        width: 350px;
    }
    .search-wrapper input:focus{
        outline:none;
    }
    .search-wrapper button{
        position: absolute;
        right:0px;
        top: 50%;
        transform: translateY(-50%);
        color:var(--main-color);
        background: none;
        border:none;
        border-radius: 0;
    }
    .links-wrapper{
        display:flex;
        gap:15px;
    }
    .link-item {
        font-family: LucidaFaxBold;
        display:flex;
        align-items:center;
    }
    .link-item .icon{
        color:var(--main-dark-color);
        font-size: 27px;
        padding-inline-end: 10px;
    }
    .link-item.has-menu{
        cursor:pointer;
        position: relative;
    }
    .link-item.has-menu .menu{
        width: calc(100% + 40px)
    }
    .link-item.has-menu:hover .menu{
        transform:translateX(-50%) translateY(0) !important;
        height: auto;
    }
    .mobile-btns{
        display: flex;
        justify-content: flex-end;
        gap:5px;
    }
    .mobile-btns .btn{
        background: none;
        color: var(--main-color);
        border: 1px solid #ddd;
    }
    .mobile-nav .nav-list{
        display: none;
        padding-top: 20px ;
    }
    .mobile-nav .nav-wrapper{
        display: block;
        position: relative;
    }
    .mobile-nav .logo-wrapper{
        top:-35px;
    }
    .mobile-nav .search-wrapper{
        padding-top: 20px;
    }
    .mobile-nav .search-wrapper input{
        width: 100%;
        color: #222;
    }
    .mobile-nav .search-wrapper button{
        background: var(--light-color);
        transform: translateY(-22%);
    }
    @media (max-width:992px) {
        .logo-wrapper{
            top: 20px;
        }
        .logo-wrapper img{
            width: 110px;
        }
    }
    
`;


export const NavListbar = styled.div`
    background:#fff;
    box-shadow: 0px 0.1px 5.1px #aaa;
    transition:.3s;
    overflow:hidden;
    .nav-list{
        display: flex;
        flex-direction: row;
        margin: 0;
        padding: 0 2px;
        transition:.3s;
        flex-wrap:wrap;
    }
    .nav-list li.nav-item{
        font-size: 15px;
        text-transform: capitalize;
        position: relative;
        color:var(--bg-color);
    }

    .nav-list li.nav-item a.nav-item-link{
        text-decoration: none;
        position: relative;
        transition: ease-in 0.2s all;
        display: block;
        padding: 10px 15px;
        color: #444;
        font-family: LucidaFaxBold;
        white-space:nowrap;
        text-transform: uppercase;
    }

    .nav-list > li.nav-item a.nav-item-link.active,
    .nav-list > li.nav-item:hover a.nav-item-link{
        color:var(--main-color);
    }
    .nav-list li.nav-item.has-menu .submenu-level-1{
        padding:  0;
        display: none;
        overflow: hidden;
        min-width: 250px;
        position: absolute;
        top: 100%;
        right:50%;
        transform: translateX(50%);
        background-color: var(--main-color);
        color:var(--light-color);
        z-index: 100;
        text-align:center;
    }
    .nav-list li.nav-item.has-menu:hover .submenu-level-1{
        height: auto;
        display: block;
    }
    .nav-list li.nav-item.has-menu .submenu-level-1 li{
        font-size: 15px;
    }

    .nav-list li.nav-item.has-menu .submenu-level-1 li:hover{
        background-color: var(--bg-color);
    }
    .nav-list li.nav-item.has-menu .submenu-level-1 a{
        color: #fff;
        display: block;
        padding: 10px 20px;
    }
    @media (min-width:993px) {
        display:block !important;
    }
    @media (max-width:992px) {
        padding-top:30px;
        .nav-list{
            display: block;
        }
        .nav-list li.nav-item.has-menu:hover .submenu-level-1{
            display: none;
        }

    }
`;



export const Dropdown = styled.ul`
    padding:0;
    position:absolute;
    top: 100% ;
    padding-top:25px;
    left:50%;
    transform:translateX(-50%) translateY(-10%);
    text-align:center;
    width:100%;
    transition:.3s;
    height:0;
    overflow:hidden;
    z-index: 10000;
`;

export const DropdownLink = styled.div`
    position:relative;
    z-index:100;
    display:flex;
    align-items:center;
`;
export const DropdownMenu = styled.ul`
    padding:0;
    background:#333;
    text-align:center;
    width:100%;
`;
export const DropdownItem = styled.li`
    width:100%;
    display:flex;
    gap:10px;
    align-items:center;
    padding: 10px 15px;
    &:hover{
        background: var(--main-color);
    }
    a{
        display:flex;
    }
`;

