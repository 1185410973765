

import { Form } from "react-bootstrap";
import { FilterChoice } from "./style";

const Index = ({ item, title, value, name, type, children, ...rest }) => {

    return <FilterChoice>
        {
            type === "select" ?
                <Form.Select aria-label="Default select example"  {...rest}>
                    {children}
                </Form.Select>
                : <Form.Check
                    type={type}
                    id={item.title + "_" + value}
                    label={title}
                    name={name}
                    value={value}
                    {...rest}
                />
        }

    </FilterChoice>
}

export default Index