

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';

import { default as LoadingPage } from '../components/loading/LoadingPage';
import { default as BrandItem } from '../components/brands/Item';

import { connect } from 'react-redux';
import { getBrandsReq } from "../api/requests";
import { default as PageTitle } from "../components/page-title/Index";
import { setDocumentTitle } from "../helper/methods";



const Index = ({ webInfo }) => {


    const [isLoading, setIsLoading] = useState(true);
    const [brands, setBrands] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        setIsLoading(true)
        getBrandsReq().then(res => {
            setIsLoading(false);
            if (res.status) {
                setBrands(res.items);
            } else {
                setError(res.message);
            }
        }).catch(e => {
            setIsLoading(false);

        });
    }, [])

    useEffect(() => {
        setDocumentTitle(webInfo.site_name, "Brands");
    }, []);

    return <div className="page brands-page">
        <Container>
            {
                isLoading ?
                    <LoadingPage />
                    : <>
                        <PageTitle title="Brands" ></PageTitle>
                        <Row>
                            {
                                brands.map((item, index) =>
                                    <Col md="3" xs="6" key={index}>
                                        <BrandItem item={item} />
                                    </Col>
                                )
                            }
                        </Row>
                    </>
            }

        </Container>
    </div>
}

function mapStateToProps(state) {
    const { webInfo } = state;
    return { webInfo };
}

export default connect(mapStateToProps, null)(Index);