
import { Button } from "react-bootstrap"
import { CardBox } from "./style"
import FormInput from '../auth/Input'
import { faArrowRight, faTag, faEnvelopeOpen, faHome, faMobile, faUser } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useRef, useState } from "react"
import { validateCheckout } from "../../helper/methods"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom"
import { CART } from "../../helper/paths"
import { getCouponReq } from "../../api/requests"

const Index = ({ setError, auth, totalCost, cart, checkOut, error, errorsRes, addCoupon }) => {

    const emailRef = useRef('');
    const fullNameRef = useRef('');
    const mobileRef = useRef('');
    const addressRef = useRef('');
    const couponRef = useRef('');
    const [errors, setErrors] = useState({
        mobile: '',
        address: '',
        fullname: '',
        couponCode: ''
    });
    const [messageCoupon, setMessageCoupon] = useState('');

    const [coupon, setCoupon] = useState({ id: '', code: '' })

    const [user, setUser] = useState({
        email: cart.shipping.email || auth.user.email,
        mobile: cart.shipping.mobile || auth.user.mobile,
        address: cart.shipping.address || auth.user.address,
        full_name: cart.shipping.fullname || auth.user.fullname,
        couponCode: cart.shipping.coupon.code ?? ''
    })

    const handleInputChange = (e) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
        setMessageCoupon('');
    }
    const validate = (mobile, address, fullname, couponCode) => {

        let res = validateCheckout(mobile, address, fullname, couponCode, coupon.id, auth.isAuthenticated, errors);
        setErrors({ ...res.errorsTemp });
        return res.isValidated;
    }

    const handleCheckout = (e) => {
        e.preventDefault();

        let email = emailRef.current ? emailRef.current.value : '';
        let fullname = fullNameRef.current ? fullNameRef.current.value : '';
        let mobile = mobileRef.current.value;
        let address = addressRef.current.value;
        let couponCode = '';//couponRef.current.value;

        if (validate(mobile, address, fullname, couponCode)) {
            checkOut(mobile, address, fullname, email, coupon);
            setError('')
        } else {
            setError('You have to login or enter all required information');
        }

    }

    const checkCoupon = (e) => {
        e.preventDefault()
        if (user.couponCode) {
            getCouponReq({
                'code': user.couponCode,
            }).then(res => {
                if (res.status) {
                    setCoupon(res.coupon);
                    addCoupon(res.coupon)
                    setMessageCoupon(`You have discount with value ${res.coupon.value} ${res.coupon.type === "percent" ? '%' : '$'} on your cart.`);
                } else {
                    setMessageCoupon(res.message);
                    addCoupon({
                        id: '',
                        code: ''
                    })
                }
            }).catch(e => {
                //setIsSending(false);
            });
        }
    }
    useEffect(() => {
        setUser(auth.user);
        if (cart.shipping.coupon.id != '') {
            let coupon = cart.shipping.coupon;
            setMessageCoupon(`You have discount with value ${coupon.value} ${coupon.type === "percent" ? '%' : '$'} on your cart.`);
        }
    }, [auth])

    return <CardBox >
        <div className="card-head">
            {
                !auth.isAuthenticated ?
                    <div className="mb-3 text-center" style={{ fontSize: '16px' }}>
                        Already have an account ? <Link to="/login" style={{ color: 'var(--main-color)', marginInlineStart: '5px' }}>Login</Link>
                    </div>
                    : ''
            }
            <div className="form-block-title">Shipping Information</div>


        </div>


        <div className="pt-4 mt-1">
            {
                !auth.isAuthenticated ?
                    <>
                        <FormInput icon={faEnvelopeOpen} type="email" value={user.email} onChange={handleInputChange} placeholder="Enter Your Email" ref={emailRef} error={errors.email || errorsRes.email} name="email" />
                        <FormInput icon={faUser} type="text" value={user.full_name} onChange={handleInputChange} placeholder="Enter Your FullName" ref={fullNameRef} error={errors.fullname || errorsRes.fullname} name="fullname" />
                    </>
                    : ''
            }
            <FormInput icon={faMobile} type="text" value={user.mobile} onChange={handleInputChange} placeholder="Enter Your Mobile" ref={mobileRef} error={errors.mobile || errorsRes.mobile} name="mobile" />
            <FormInput icon={faHome} type="text" value={user.address} onChange={handleInputChange} placeholder="Enter Your Address" as="textarea" ref={addressRef} error={errors.address || errorsRes.address} name="address" />
            {/*<form onSubmit={checkCoupon}>
                <label for="formBasiccouponCode" className="mb-3">Do you have a coupon ? </label>
                <div style={{ display: 'flex', gap: '10px' }}>
                    <FormInput icon={faTag} type="text" required value={user.couponCode} onChange={handleInputChange} placeholder="Enter Your Coupon" ref={couponRef} error={errors.couponCode || errorsRes.couponCode} name="couponCode" />
                    <button className="btn btn-circle" title="Click to check coupon's details"><FontAwesomeIcon icon={faArrowRight} /></button>
                </div>
                {
                    messageCoupon !== '' && user.couponCode !== '' ?
                        <div className="coupon-details-box">
                            {messageCoupon}
                        </div> : ''
                }

            </form>*/}
        </div>

        <div className="text-error  text-center my-3">
            {error}
        </div>
        <div className="btns-wrapper">
            <Button onClick={handleCheckout} disabled={totalCost > 0 ? false : true}>CheckOut</Button>
            <Link to={CART} >  Return to cart</Link>
        </div>
    </CardBox >
}

export default Index