import { LoadingBox } from "./style"
import { ThreeDots } from 'react-loading-icons'
const Index = () => {
    return <LoadingBox>
        <div>
            <span>Please Waiting ...</span>
            <div>
                <ThreeDots />
            </div>
        </div>
    </LoadingBox>
}

export default Index