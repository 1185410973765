

import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from "react-router-dom"
import { NavListbar } from './style';
import { CONTACT, HOME, toCategory } from '../../helper/paths';

const Index = ({ categories, isDisplayed, setIsDisplayedNav }) => {


    return <NavListbar style={{ display: isDisplayed ? 'block' : 'none' }} onClick={() => setIsDisplayedNav(false)}>
        <Container>
            <Row>
                <Col md="2"></Col>
                <Col md="10">
                    <div className="nav-list-wrapper" >
                        <ul className="nav-list list">
                            <li className="nav-item"><NavLink to={HOME} exact activeClassName="active" className="nav-item-link">Home</NavLink></li>

                            {
                                categories.map((category, index) =>
                                    <li className="nav-item" key={index}><NavLink to={toCategory(category.slug)} className="nav-item-link">{category.title}</NavLink></li>
                                )
                            }
                            <li className="nav-item"><NavLink to={CONTACT} activeClassName="active" className="nav-item-link">Contact US</NavLink></li>
                        </ul>

                    </div>
                </Col>
            </Row>
        </Container>
    </NavListbar>
}

export default Index