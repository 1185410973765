
import styled from 'styled-components';


export const Banner = styled.div`
    display: block;
    text-align: center;
    position: relative;
    background: var(--main-color);
    color:#fff;
    background-image: ${props => props.bg ? props.bg : ""};
    background-attachment:fixed;
    background-size:cover;
    min-height:270px;
    .banner-title{
        padding:10px 10px 20px;
    }
    .banner-desc{
        margin-bottom:10px;
    }
    a{
        color:#fff;
        background:var(--main-color);
        border: 1px solid var(--main-color);
    }
    a:hover{
        color: var(--main-color);
        border: 1px solid var(--main-color);
        background:none;
    }
    
`;