
import styled from 'styled-components';

export const SocialList = styled.ul`
    display:flex;
    .social-icon{
        width:25px;
        font-size:20px;
        margin-inline-end:5px;
    }

`;

export const Footer = styled.footer`
    background-color: var(--bg-color);
    color: #fff;
    position: relative;
    z-index: 100;
    
    .footer-title{
        font-size: 17px;
        margin: 0;
    }
    .footer-top-wrapper{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0;
    }
    .social-wrapper{
        display: flex;
        gap: 20px;
        height: 100%;
        align-items: center;
    }
    .socialmedia-list {
        flex-direction: row;
    }
    .socialmedia-list li .social-icon{
        color:#fff;
        font-size:10px;
        font-size:22px;
    }
    .footer-nav-list{
        display: flex;
        justify-content: space-between;
        border-top:1px solid #999;
        padding-top: 20px;
        font-weight: bold;
    }
    .footer-nav-list a{
        color: #fff;
    }
    .logo-img{
        max-width: 100%;
        margin:10px 0 10px;
    }

    .footer-links-list{
        display: flex;
        gap:15px;
    }
    .footer-bottom{
        background: var(--main-color);
        color:var(--bg-color);
    }
    .copyrights{
        padding: 8px 0;
        text-align: center;
    }
    @media (max-width:992px) {
        .footer-top-wrapper{
            padding:30px 0;
            display: block;
            text-align:center;
        }
        .social-wrapper{
            justify-content:center;
            margin:20px auto;
        }
        .footer-links-list{
            justify-content:center;
        }
    }

`;