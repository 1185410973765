import { Fade } from "react-reveal"
import { SearchRow } from "./style"

const Index = ({ title, children }) => {

    return <SearchRow >
        <Fade left>
            <h3 className='title'>{title}</h3>
        </Fade>
        <div>
            {children}
        </div>
    </SearchRow >
}

export default Index