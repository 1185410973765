
import React from 'react';
import { Fade } from 'react-reveal';
import { AuthWrapper, AuthCard } from './style.js';


const Index = ({ title, children, ...rest }) => {


    return <AuthWrapper>
        <Fade top>
            <AuthCard  {...rest}>
                <h3 className="auth-card-title">{title}</h3>

                {children}

            </AuthCard>
        </Fade>
    </AuthWrapper>

};

export default Index