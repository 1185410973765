

import { SectionHead } from './style'

const Index = ({ title, ...props }) => {
    return <SectionHead {...props} >
        <h3 className="section-title">{title}</h3>
        <div className="section-line"><span></span></div>
    </SectionHead>
}

export default Index