
import { useState } from 'react';
import { connect } from 'react-redux';

import { Modal, Form, Row, Col } from 'react-bootstrap';
import { updatePersonalInfoReq } from '../../api/requests';
import { Btn } from '../style';
import { default as ModalInput } from "./ModalInput"

const Index = ({ auth: { user }, show, handleClose, setUpdated }) => {


    const [info, setInfo] = useState({
        address: user.address,
        mobile: user.mobile,
        full_name: user.full_name
    });

    const handleInputChange = (e) => {
        setInfo({
            ...info,
            [e.target.name]: e.target.value
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        updatePersonalInfoReq(info).then(res => {
            if (res.status) {
                setUpdated();
                handleClose();
            } else {

            }
        }).catch(e => e);

    }

    return <Modal show={show ? 1 : 0} onHide={handleClose}>
        <Modal.Header closeButton style={{ padding: '9px 15px' }}>
            <Modal.Title style={{ fontSize: '15px' }}>Edit Personal Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form onSubmit={handleSubmit}>
                <div>
                    <ModalInput type="text" placeholder="Your Name" label="Name" value={info.full_name} required handleInputChange={handleInputChange} name="full_name" />
                </div>

                <div>

                    <ModalInput type="text" placeholder="Your Address" as="textarea" label="Your Address" value={info.address} handleInputChange={handleInputChange} name="address" />

                </div>
                <Row>
                    <Col md="6">
                        <ModalInput type="text" placeholder="Mobile" label="Mobile" value={info.mobile} handleInputChange={handleInputChange} name="mobile" />
                    </Col>
                </Row>


                <div>
                    <Btn type="submit">
                        Save
                    </Btn>
                    <Btn type="button" onClick={handleClose} bg="#ddd">
                        Cancel
                    </Btn>
                </div>

            </Form>
        </Modal.Body>
    </Modal>
}

function mapStateToProps(state) {
    const { auth } = state;
    return { auth };
}


export default connect(mapStateToProps, null)(Index);