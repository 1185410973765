import { Order } from "./style";

const Index = ({ item, ...rest }) => {



    return <Order {...rest}>
        <div className="order-row">
            <div className="order-row-info">
                <div>{item.date}</div>
                <div>{item.total_price} $</div>
                <div>{item.order_items.length} items</div>
            </div>
            <div className="order-status">
                {
                    <span>{item.status_txt}</span>
                }
            </div>
        </div>
        <div className="order-items">
            {
                item.order_items.map((order_item, index) =>
                    <div key={index}>
                        <div className="order-item-row">
                            <div>
                                <span>Title : </span><span> {order_item.product.title}  </span>
                            </div>
                            <div>
                                <span>Quantity : </span><span> {order_item.quantity}  </span>
                            </div>

                            <div>
                                <span>Price : </span><span> {order_item.product.price_currency}  </span>
                            </div>

                        </div>

                    </div>
                )
            }
        </div>
    </Order>

}

export default Index;