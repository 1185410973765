
import { Form, FormGroup, FormControl, FormText } from 'react-bootstrap';

const Index = ({ error, label, name, placeholder, handleInputChange, value, type, icon, ...rest }) => {

    return <FormGroup className="mb-3" controlId={name} >
        <Form.Label>{label}</Form.Label>
        <FormControl size="sm" type={type} placeholder={placeholder}
            onChange={handleInputChange} name={name} value={value} {...rest} />
        <FormText className="text-muted input-error">
            {error ? "* " + error : ''}
        </FormText>
    </FormGroup>
}

export default Index