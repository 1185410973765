
export const getToken = () => {
    let cookies = document.cookie;
    let cookie_token = "token=";
    let token;
    if (cookies !== '') {
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cookie_token) === 0) {
                token = c.substring(cookie_token.length, c.length);
            }
        }
    }
    return token;
}

export const setToken = (token) => {
    document.cookie = "token=" + token + ";path=/";
}

export const removeToken = () => {
    document.cookie = 'token=; Max-Age=-99999999;';
}