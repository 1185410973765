import { DropdownItem } from './style'

const Index = ({ children, ...rest }) => {

    return <DropdownItem {...rest}>
        {children}
    </DropdownItem>

}

export default Index