

import { combineReducers, createStore } from 'redux';
import { reducer as CartReducer } from './cart';
import { reducer as AuthReducer } from './auth';
import { reducer as WebReducerReducer } from './web_info';
import { reducer as RatingsReducer } from './ratings';

const rootReducer = combineReducers({
    cart: CartReducer,
    ratings: RatingsReducer,
    auth: AuthReducer,
    webInfo: WebReducerReducer
});

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


export default store;