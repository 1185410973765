
import styled from 'styled-components';


export const CartItem = styled.div`
    border:1px solid #ddd;
    padding:15px 20px;
    margin-bottom :10px;
    position:relative;
    .card-item-top{
        display: flex;
        justify-content:space-between;
        gap:15px;
        padding-bottom: 15px;
    }
    .card-item-start{
        display:flex;
        gap:15px;
    }
    .card-item-img img{
        width:60px;
    }
    .card-item-title{
        font-size:17px;
    }
    .card-item-prefix{
        color:#a2a2a2;
        display:flex;
        gap:5px;
    }
    .card-item-end{
        display:flex;
        gap:10px;        
        align-items:start;

    }
    .card-item-count{
        display:flex;
        gap:10px;
        align-items:center;
    }
    .card-item-count span{
        font-weight:bold;
    }
    .card-item-count .btn{
        background:none;
        width:30px;
        height: 30px;
        line-height: 25px;
        color:var(--bg-color);
        border: 1px solid var(--bg-color);
        text-align:center;
        padding:0;
    }
    .card-item-price{
        font-weight:bold;
        font-size:15px; 
        width:100px;
        max-width:100px;
        min-width:100px;
        text-align:end;
    }
    .card-item-bottom{
        display:flex;
        justify-content: space-between;
        padding-top:10px ;
        border-top: 1px solid #f3f3f3;
    }
    .card-item-actions .btn{
        background:none;
        border:none;
        color:var(--bg-color);
        font-size:13px;
        padding:0;
    }
    .card-item-actions .btn span{
        padding-inline-start:5px;
    }
    .card-item-actions .btn.remove{
        color: red;
    }
    .card-item-total{
        font-weight:bold;
        font-size:16px;
    }

    .card-item-count-2{
        height:30px;
        line-height:30px;
        width:30px;
        background: var(--bg-color);
        color:#fff;
        text-align:center;
        border-radius:50%; 
        position:absolute;
        top:0;
        left:0;
    }
    @media (max-width:992px){
        .card-item-top{
            display: block;
        }
        .card-item-start{
           margin-bottom: 30px;
        }
        .card-item-end{
            justify-content:space-between;
        }
    }
`;

export const CardBox = styled.div`
    border: 1px solid #ddd; 
    padding: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    .card-head {
        padding-bottom:20px;
        padding-top:20px;
    }        
    .form-block-title{
        font-size: 20px;
        position: relative;
    }
    .form-block-title:after{
        height: 2px;
        width: 40px;
        position: absolute;
        content:'';
        bottom: -10px;
        left:0;
        background: var(--main-color);
    }
    .form-control {
        font-size:14px;
    }
    .form-label{    
        font-size:18px !important;
    }
    .btns-wrapper{
        text-align:center;
    }
    .links-wrapper{
        display:flex;
        gap:20px;
        justify-content:space-around;
    }
    .btn{
        width:100%;
        background:var(--bg-color);
        border:1px solid var(--bg-color);
        margin-bottom:20px;
    }
    .btn:hover{
        box-shadow : 1px 5px 5px #ddd !important;
    }
    .btn-circle{
        width:35px;
        height:35px;
        border-radius:50%;
        background-color: #fff;
        padding:0;
        transition:.5s
    }
    .coupon-details-box{
        border:1px solid #f1f1f1;
        padding: 20px 15px;
        background: #f8f8f8;
        color: #10ae09;
        text-align:center;
        transition:.5s
    }
    @media( max-width : 992px){
        .cart-total-head {
            display:block;
        } 
        .form-block-title{
            padding-bottom: 10px;
        }
    }
`;

export const CardBoxItem = styled.div`
    margin-bottom:15px;
    font-weight:bold;
    .input-error{
        min-height:5px;
    }
    .card-item-row{
        display : flex;
    }
    label{
        width: 95px;
    }
`;