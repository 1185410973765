import React, { useReducer, createContext, useMemo } from "react";

export const AuthContext = createContext();

export const ACTIONS = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    FETCHING: "FETCHING"
};

const initialState = {
    isAuthenticated: false,
    user: {},
    isFetching: false
};

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.FETCHING:
            return {
                ...state,
                isFetching: true
            };
        case ACTIONS.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                isFetching: false,
                ...action.payload
            };
        case ACTIONS.LOGOUT:
            return {
                isFetching: false,
                ...initialState
            };
        default:
            return state;
    }
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const contextValue = useMemo(() => {
        return { state, dispatch };
    },
        [state, dispatch]); return (
            <AuthContext.Provider value={contextValue}>
                {children}
            </AuthContext.Provider>
        );
};