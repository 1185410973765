import { useEffect } from 'react';
import { connect } from 'react-redux';
import { setDocumentTitle } from '../helper/methods';
import { default as Details } from './Details'

const Index = ({ webInfo }) => {

    useEffect(() => {
        setDocumentTitle(webInfo.site_name, "About");
    }, []);
    return <div className='page'>
        <Details item={webInfo.about} />
    </div>
}

function mapStateToProps(state) {
    const { webInfo } = state;
    return { webInfo };
}

export default connect(mapStateToProps, null)(Index);