
/*
*************************
    Global Styles
    *************
*************************
*/


import styled from 'styled-components';
 
export const Box = styled.div`
    background-color: #fff;
    border-radius: 7px;
    padding: 10px;
    margin-bottom: 10px;
    .box-title{
        background-color: #f2f2f2;
        padding: 5px ;
    }
`;

export const OverlayBox = styled.div`
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display:flex;
    align-items:center;
    justify-content:center;
    background: ${props => props.bg ? props.bg : ''}
`;

export const ChoicesList = styled.ul`
    list-style:none;
    display: ${props => props.isDisplay ? 'block' : 'none'};
    position:absolute;
    border: 1px solid #f2f2f2;
    top: calc(100% + 10px);
    left: 50%;
    transform:translateX(-50%);
    z-index:1035;
    min-width: min-content;
    white-space: nowrap;
    padding: 0;
    border-radius:2px;
    background: ${props => props.bg ? props.bg : '#f2f2f2'};
    li{
        padding: 7px 20px;
    }
    li:not(:last-child){
        border-bottom: 1px solid #f9f9f9;
    }
    li:hover{
        cursor:pointer;
        background:#f2f2f2; 
    }
    @media (max-width: 768px) {
        left: 0;
        transform:none;
    }

`;

export const ViewAllBtn = styled.button`
    font-size:13px;
    text-align:center;
    padding-top: 5px;
    margin-top: 5px;
    border-top:1px solid #f2f2f2 !important;
    width:100%;
`;

export const Btn = styled.button`
    font-size:14px;
    text-align:center;
    margin-top: 5px;
    border-radius:3px;
    padding:7px 20px;
    background: ${props => props.bg ? props.bg : "var(--main-color)"};
    border:1px solid ${props => props.bg ? props.bg : "var(--main-color)"} !important;
    transition:.3s;
    &:hover,&:focus{
        border:1px solid ${props => props.bg ? props.bg : "var(--main-color)"} !important;
    }
`;



export const ProductItem = styled.div`
    display: block;
    text-align: center;
    margin-bottom: 10px;
    position: relative;
    padding: 15px;
    transition:.3s;
    height: 100%;
    overflow:hidden;
    &.new-product{
        border:1px solid #c8c8c8;
    }
    &:hover{
        box-shadow:0  1px 5px 3px #ddd;
    }
    img{
        max-height: 250px;
        margin-bottom: 15px;
    }
    .product-item-line{
        height: 5px;
        border-radius: 100%;
        background: var(--main-color);
        width: 60%;
        margin:auto;
    }
    .product-item-title{
        padding: 15px 0;
        text-align: center;
        font-size: 18px;
        font-family: LucidaFaxBoldItalic;
        margin: 0;
    }
    .product-item-price{
        font-size: 18px;
        font-weight: bold;
        color: var(--main-color);
    }
    .product-status{
        position: absolute;
        top: 0;
        left: 0;
        max-width: max-content;
        padding: 5px 10px;
        z-index: 5;
    }
    .product-status.status-new{
        background: var(--main-color);
    }
    .product-status.status-out{
        font-size:13px;
        background: #0009;
        color: #fff;
        transform: rotateZ(-45deg);
        top: 15px;
        left: -40px;
        padding: 5px 40px;
    }
    .new-products-section .product-item{
        border: 1px solid var(--bg-color);
    }
    .product-status.status-orders{
        left: 50%;
        transform: translateX(-50%);
        background: var(--bg-color);
        color: var(--main-color);
        border-radius: 50%;
        width: max-content;
        max-width: none;
        top: 0px;
        font-size: 19px;
        z-index: 100;
        transition:.3s;
        padding:5px;
    }
    .product-status.status-orders span{
        display: block;
        border-radius: 50%;
        height: 70px;
        line-height: 65px;
        width: 70px; 
        border: 1px solid var(--main-color)
    }

    &.top-product-item{
        padding: 0 !important;
        padding-top: 20px !important;
        overflow: hidden;
        border:none;
    }
    .top-product-item-back{
        padding: 10px;
        position: absolute;
        top: 20px;
        bottom:0;
        left:0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        align-items: center;
        background-color: var(--bg-color);
        transform: translateY(100%);
        transition: .5s;
        z-index:1;
    }
    .top-product-item-front{
        transition: .5s;
        border: 1px solid var(--bg-color);
        width: 100%;
        padding-top: 70px;
        height:100%
    }
    &.top-product-item:hover {
        box-shadow: none !important;
    }
    &.top-product-item:hover .top-product-item-back{
        transform: rotateY(0);
    }
    .add-to-card{
        width: 80%;
        border: none;
        padding: 10px ;
        margin: auto;
    }
    
    @media(max-width: 992px){
        height : auto;
        margin-bottom:20px;
    }
`;