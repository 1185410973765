

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';


//import { default as LoadingPage } from '../components/loading/LoadingPage';


import { useParams } from "react-router-dom";
import { getProductsReq } from "../api/requests";
//import { ProductItem } from "../components/style";
//import { Button } from "react-bootstrap";



const Index = () => {

    const { id } = useParams();

    //const [isLoading, setIsLoading] = useState(true);
    //const [products, setProducts] = useState([]);
    //const [error, setError] = useState('');


    useEffect(() => {
        //setIsLoading(true)
        getProductsReq().then(res => {
           // setIsLoading(false);
            if (res.status) {
                //setProducts(res.items);
            } else {
                //setError(res.message);
            }
        }).catch(e => {
            //setIsLoading(false);

        });
    }, [id])


    return <div className="page product-page">
        <Container>
            <Row>
                <Col md="2">
                  
                  
                </Col>
                <Col md="10">
                   
                   
                </Col>
            </Row>
        </Container>
    </div>
}

export default Index;