


import styled from 'styled-components';

export const ContactBox = styled.div`
    background-color: #fff;
    border-radius: 7px;
    display:flex;
    align-items:center;
    padding: 10px;
    margin-bottom: 10px;
    gap:20px;
    .icon-box{
        width:50px;
        min-width:50px;
        height:50px;
        line-height:50px;
        border-radius: 50%;
        background:var(--bg-color);
        text-align:center;
        color:#fff;
        font-size:22px;
    }
`;
