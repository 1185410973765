
export const ACTIONS = {
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    FETCHING: "FETCHING"
};


export function login(user) { return { type: ACTIONS.LOGIN, payload: user } }
export function logout() { return { type: ACTIONS.LOGOUT } }


const initialState = {
    isAuthenticated: false,
    user: {},
    isFetching: false
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.FETCHING:
            return {
                ...state,
                isFetching: true
            };
        case ACTIONS.LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                isFetching: false,
                user: action.payload
            };
        case ACTIONS.LOGOUT:
            return {
                isFetching: false,
                ...initialState
            };
        default:
            return state;
    }
};

