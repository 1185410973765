import { useEffect, useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { default as SectionHead } from '../section-head/Index';

import { getNewProductsReq } from '../../api/requests'
import { default as Item } from './Item';

const Index = ({title}) => {
    const [products, setProducts] = useState([])
    //const [isLoading, setIsLoading] = useState(true);
   // const [error, setError] = useState('');
    useEffect(() => {

        getNewProductsReq().then(res => {
            //setIsLoading(false);
            if (res.status) {
                setProducts(res.items);
            } else {
                //setError(res.message);
            }
        }).catch(e => {
            //setIsLoading(false);
        });

    }, []);
    return <div className="new-products-section section products-section">
        <Container>
            {
                title !== '' ?
                    <SectionHead title={title} className="text-center mx-auto"></SectionHead>
                    : ''
            }
            <Row>
                {
                    products.map((product, index) =>
                        <Col md={3} key={index} className="mb-4">
                            <Fade top>
                                <Item item={product} />
                            </Fade>
                        </Col>
                    )
                }
            </Row>

        </Container>
    </div >
}

export default Index