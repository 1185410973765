


import styled from 'styled-components';

export const PageTitle = styled.div`
    padding: 10px 0;
    margin-bottom: 10px;
    
    .title{
        font-size:24px;
        text-align:center;
    }
    .section-head-line{
        width: 50%;
        height: 5px;
        background-color: var(--bg-main-color);
        margin-bottom: 10px;
        position: relative;
    }
    .section-head-line-icon{
        font-size: 41px;
        position: absolute;
        bottom: 0px;
        right: -4px;
        transform: translateX(-50%);
        background: #fff;
        color: var(--main-color);
        height: 30px;
        line-height: 23px;
    }
    .section-text{
        margin-bottom: 30px;
    }
`;
