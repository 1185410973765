
import React, { useEffect, useState } from "react";
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

//pages
import { default as Home } from './pages/Home';
import { default as NotFound } from './pages/NotFound'
//components
import { default as Header } from './components/header/Index';
import { default as Footer } from './components/footer/Index';
import { default as LoadingBox } from './components/loading/LoadingBox';
import { default as WhatsappBtn } from './components/whatsapp-btn/Button';
 //pages paths
import { Routes } from './helper/paths';
import * as Paths from './helper/paths';
import ProtectedRoute from './helper/ProtectedRoute';
import PublicRoute from './helper/PublicRoute';

import { getWebInfoReq, logoutReq, meReq } from './api/requests'
import './assets/style.css';
import { getToken, removeToken } from "./api/cookies";

import { login, logout } from "./redux/auth";
import { update } from "./redux/web_info";
import ScrollToTop from "./helper/ScrollToTop";

function App({ loginDispatch, logoutDispatch, auth, webInfo, updateWenInfoDispatch }) {

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');


  const logout = () => {

    logoutReq().then((res) => {
      if (res.status) {
        removeToken();
        logoutDispatch()
      }
    }).catch(e => {
      logoutDispatch()
    })

  };

  const getWebInfo = () => {
    getWebInfoReq().then(res => {
      if (res.status) {
        updateWenInfoDispatch(res.webinfo);
      } else {
        setError(res.message);
      }
      setIsLoading(false);
    }).catch(e => {
      setIsLoading(false);
    });
  }
  useEffect(() => {

    setIsLoading(true);
    getWebInfo();
    if (getToken()) {
      meReq().then(res => {
        if (res.status) {
          loginDispatch(res.user);
        }
        setIsLoading(false);
      }).catch(e => {
        setIsLoading(false);
        logoutDispatch()
      });
    }


  }, []);

  return (
    <div >
      <Helmet>
        <title>Perfume Testers</title>
        <meta name="theme-color" content="#ccc" />
        <meta name="description" content="Perfume Testers, ORIGINAL, ORIGINAL TESTERS, ORIGINAL UNBOXED TESTERS, GIFTS" />
        <meta name="keywords" content="Perfume Testers, ORIGINAL, ORIGINAL TESTERS, ORIGINAL UNBOXED TESTERS, GIFTS" />
        <meta name="author" content="Provided by Swan Technology" />
        <meta property="og:title" content="Perfume Testers" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content="http://perfume-testers.com" />
        <meta property="og:image" content="http://perfume-testers.com/logo.png" />
        <meta name="twitter:title" content="Perfume Testers" />
        <meta name="twitter:description" content="Perfume Testers, ORIGINAL, ORIGINAL TESTERS, ORIGINAL UNBOXED TESTERS, GIFTS" />
        <meta name="twitter:image" content="http://perfume-testers.com/logo.png" />

      </Helmet>
      {
        isLoading ? <LoadingBox />
          : <>

            <BrowserRouter >
              <Header logout={logout} />
              <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                pauseOnHover
              />
              {
                auth.isFetching ?
                  <div className="bg-white"> Loading .. </div> : ''
              }

              <ScrollToTop />

              <Switch>
                <Route path={Paths.HOME} exact component={Home} />
                {
                  Routes.map((page, index) =>
                    page.isPrivate ?
                      <ProtectedRoute
                        isAuthenticated={auth.isAuthenticated}
                        path={page.path}
                        logout={logout}
                        component={page.component}
                        key={index}
                        exact
                      />
                      : <PublicRoute
                        path={page.path}
                        login={login}
                        component={page.component}
                        key={index}
                        exact
                      />
                  )
                }
                <Route component={NotFound} />
              </Switch>
              <WhatsappBtn message="Hello" number={webInfo.contact.mobile} />
              <Footer />
            </BrowserRouter>
          </>
      }
    </div>
  );
}


function mapStateToProps(state) {
  const { auth, webInfo } = state;
  return { auth, webInfo };
}
const mapDispatchToProps = dispatch => {
  return {
    loginDispatch: (user) => dispatch(login(user)),
    logoutDispatch: () => dispatch(logout()),
    updateWenInfoDispatch: (info) => dispatch(update(info))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);

