


import { Drawer, DrawerCloseBtn, DrawerHeader } from './drawer.style';

const Index = ({ isDisplayed, setIsDisplayedDrawer, children, ...rest }) => {


    return <Drawer isDisplayed={isDisplayed} {...rest}>
        <DrawerHeader> <DrawerCloseBtn onClick={() => setIsDisplayedDrawer(0)} /> </DrawerHeader>
        {children}
    </Drawer >
}

export default Index