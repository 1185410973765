

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { default as LoadingPage } from '../components/loading/LoadingPage';
import { default as ProductItem } from '../components/product/Index';

import { useParams } from "react-router-dom";
import { filterProductsReq, getBrandProductsReq } from "../api/requests";
import { default as PageTitle } from "../components/page-title/Index";
import { default as Sidebar } from "../components/sidebar/Index";
import { filterProducts, setDocumentTitle } from '../helper/methods';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";


const Index = ({ webInfo }) => {

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState('');
    const [isFiltering, setIsFiltering] = useState(false);
    const [brand, setBrand] = useState({});
    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');
    const [showFilter, setShowFilter] = useState(0);

    const [filter, setFilter] = useState({
        types: [],
        brands: [id]
    });

    const handleDisplayFilter = () => {
        setShowFilter(!showFilter)
    }


    const handleFilterReq = (filter, order) => {
        handleDisplayFilter();
        filterProductsReq({ brand_id: brand.id, ...filter, order: order }).then(res => {
            if (res.status) {
                setProducts(res.products)
            }
            setIsFiltering(false);
        }).catch(e => console.log(e))
    }

    const handleFilterValues = (input_name, checked, value) => {
        setIsFiltering(true);
        let filterP = { ...filterProducts(input_name, checked, value, filter) };
        setFilter({ ...filterP });
        handleFilterReq(filterP, order);
    }

    const handleSort = (e) => {
        setIsFiltering(true);
        setOrder(e.target.value);
        handleFilterReq(filter, e.target.value);
    }
    const handleFilter = (e) => {
        let input_name = e.target.name;
        handleFilterValues(input_name, e.target.checked, e.target.value);

    }


    useEffect(() => {
        setIsLoading(true)
        getBrandProductsReq(id).then(res => {
            setIsLoading(false);
            setFilter({
                types: [],
                brands: [id]
            })
            if (res.status) {
                setBrand(res.data.item);
                setProducts(res.data.products);
                setDocumentTitle(webInfo.site_name, res.data.item.title);

            } else {
                setError(res.message);
            }
        }).catch(e => {
            setIsLoading(false);

        });
    }, [id])


    return <div className=" category-page page">
        <Container>

            {
                isLoading ?
                    <LoadingPage />
                    :
                    <>

                        <Row>
                            <Col md="2">
                                <Sidebar item={brand} handleDisplayFilter={handleDisplayFilter} handleFilter={handleFilter} handleSort={handleSort} className={showFilter ? "" : "hide"} />
                            </Col>
                            <Col md="10">
                                <div className=" ">
                                    <>
                                        <PageTitle title={brand.title} >
                                            <Button className="bg-grey mobile-btn "
                                                style={{
                                                    color: 'var(--bg-color)', position: 'absolute', right: '0',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)', background: 'none', border: '1px solid var(--bg-color)'
                                                }}
                                                onClick={handleDisplayFilter}
                                            ><FontAwesomeIcon icon={faSlidersH} /></Button></PageTitle>
                                        {
                                            isFiltering ?
                                                <LoadingPage /> :
                                                <Row className="products-section">
                                                    {
                                                        products.map((item, index) =>
                                                            <Col md="3" xs="6" key={index}>
                                                                <ProductItem item={item} />
                                                            </Col>
                                                        )
                                                    }
                                                </Row>
                                        }
                                    </>
                                </div>
                            </Col>
                        </Row>
                    </>

            }
        </Container>
    </div >
}

function mapStateToProps(state) {
    const { webInfo } = state;
    return { webInfo };
}

export default connect(mapStateToProps, null)(Index);