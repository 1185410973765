
import { useEffect } from 'react';
import { connect } from 'react-redux';

import { default as Slider } from '../components/slider/Index';
//import { default as Abouts } from '../components/abouts/Abouts';
import { default as NewProducts } from '../components/new-products/Index';
import { default as TopProducts } from '../components/top-products/Index';
import { default as Brands } from '../components/brands/Index';
import { default as CategoriesSection } from '../components/categories-section/Index';
import { default as Banner } from '../components/banner/Index';
import { setDocumentTitle } from '../helper/methods';


const Index = ({ webInfo }) => {


    useEffect(() => {
        setDocumentTitle(webInfo.site_name, "Home", webInfo.site_name, "http://perfume-testers.com/logo.png", webInfo.site_name)
    }, []);
    return <div >
        <Slider />
        {
            /*
            <Abouts abouts={state.abouts} />
            */
        }
        <NewProducts title={webInfo.section_titles.new} />
        {
            webInfo.banner ?
                <Banner banner={webInfo.banner} />
                : ''
        }
        <TopProducts title={webInfo.section_titles.top} />
        <CategoriesSection categories={webInfo.categories} />
        <Brands brands={webInfo.brands} title={webInfo.section_titles.brands} />
    </div>
}


function mapStateToProps(state) {
    const { webInfo } = state;
    return { webInfo };
}

export default connect(mapStateToProps, null)(Index);

