
import { CategoryItem } from "./style";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
    Navigation, Autoplay
} from 'swiper';


SwiperCore.use([Navigation, Autoplay]);

const Index = ({ item }) => {

    return <CategoryItem>
        <h3 className="category-item-title">{item.title}</h3>
        <div>
            <Swiper
                style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }}
                spaceBetween={20}
                navigation={false}
                className="product-slider"
                watchSlidesProgress
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    360: {
                        slidesPerView: 1,
                    },
                    640: {
                        slidesPerView: 2,
                        // spaceBetween: 20,
                    },
                    768: {
                        slidesPerView: 3,
                        //spaceBetween: 40,
                    }
                }}
            >
                {
                    item.products ?
                        item.products.map((product, index) =>
                            <SwiperSlide key={index}>
                                <Link to={"products/" + product.slug} style={{ display: 'block', overflow: 'hidden', position: 'relative' }}>
                                    <div >
                                        {
                                            product.in_stock === 0 ? < div className="product-status status-out"><span>Out Of Stock</span></div>
                                                : ''
                                        }
                                    </div>

                                    <img src={product.image_thumb_path} alt="" />
                                </Link>
                            </SwiperSlide>
                        ) : ''
                }
            </Swiper>

        </div>

    </CategoryItem >

}

export default Index