

/**************************** 
********** Pages ***********
****************************/

import { default as Register } from '../pages/Register'
import { default as Login } from '../pages/Login'
import { default as ForgetPass } from '../pages/ForgetPass'
import { default as ResetPass } from '../pages/ResetPass'
import { default as Profile } from '../pages/Profile'
import { default as Search } from '../pages/Search'
import { default as Product } from '../pages/Product'
import { default as Products } from '../pages/Products'
import { default as ContactUs } from '../pages/ContactUs'
import { default as Brands } from '../pages/Brands'
import { default as Brand } from '../pages/Brand'
import { default as Category } from '../pages/Category'
import { default as Cart } from '../pages/Cart'
import { default as Shippment } from '../pages/Shippment'
import { default as Checkout } from '../pages/Checkout'
import { default as About } from '../pages/About'

//auth
export const LOGIN = "/login";
export const REGISTER = "/register";
export const FORGET_PASS = "/forget_pass";
export const FORGET_PASS_TOKEN = "/forget_pass?token=:token";
export const RESET_PASS = "/reset_pass";
//brands 
export const BRANDS = "/brands";
export const BRANDS_ITEM = "/brands/:id";
//products
export const PRODUCTS = "/products";
export const PRODUCTS_ITEM = "/products/:id";
//category
export const CATEGORY = "/categories/:id";
//profile
export const PROFILE = "/profile";
export const HOME = "/";
//cart
export const CART = "/cart";
export const SHIPPMENT = "/shippment";
export const CHECKOUT = "/checkout";
//contact
export const CONTACT = "/contact";
//seacrh
export const SEARCH = "/search";
//about
export const ABOUT = "/about";


/**************************** 
***** Paths for links *******
****************************/

//link to all constractors belongs to a type

export const toProfile = (id) => {
    return `/profile/${id}`;
}
export const toProduct = (id) => {
    return `/products/${id}`;
}
export const toCategory = (id) => {
    return `/categories/${id}`;
}
export const toBrand = (id) => {
    return `/brands/${id}`;
}

/**************************** 
********** Routes ***********
****************************/
//routes in App.js for navigation
export const Routes = [
    {
        path: LOGIN,
        component: Login,
        isPrivate: false
    },
    {
        path: REGISTER,
        component: Register,
        isPrivate: false
    },
    {
        path: FORGET_PASS,
        component: ForgetPass,
        isPrivate: false
    },
    {
        path: RESET_PASS,
        component: ResetPass,
        isPrivate: false
    },
    {
        path: PRODUCTS,
        component: Products,
        isPrivate: false
    },
    {
        path: PRODUCTS_ITEM,
        component: Product
    },
    {
        path: PROFILE,
        component: Profile,
        isPrivate: true
    },
    {
        path: SEARCH,
        component: Search,
        isPrivate: false
    },
    {
        path: CONTACT,
        component: ContactUs,
        isPrivate: false
    },
    {
        path: BRANDS,
        component: Brands,
        isPrivate: false
    },
    {
        path: BRANDS_ITEM,
        component: Brand,
        isPrivate: false
    },
    {
        path: CATEGORY,
        component: Category,
        isPrivate: false
    },
    {
        path: CART,
        component: Cart,
        isPrivate: false
    },
    {
        path: ABOUT,
        component: About,
        isPrivate: false
    },
    {
        path: PROFILE,
        component: Profile,
        isPrivate: true
    },
    {
        path: CHECKOUT,
        component: Checkout,
        isPrivate: false
    },
    {
        path: SHIPPMENT,
        component: Shippment,
        isPrivate: false
    }

]