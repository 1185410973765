
import styled from 'styled-components';

export const SectionHead = styled.div`
    width: max-content;
    margin-bottom: 40px;
    .section-title{
        font-size: 24px;
        width: max-content;
        margin: auto;
    }
    .section-head-line{
        width: 50%;
        height: 5px;
        background-color: var(--bg-main-color);
        margin-bottom: 10px;
        position: relative;
    }
    .section-head-line-icon{
        font-size: 41px;
        position: absolute;
        bottom: 0px;
        right: -4px;
        transform: translateX(-50%);
        background: #fff;
        color: var(--main-color);
        height: 30px;
        line-height: 23px;
    }
    .section-text{
        margin-bottom: 30px;
    }
`;