

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { default as LoadingPage } from '../components/loading/LoadingPage';
import { default as ProductItem } from '../components/product/Index';
import { Helmet } from "react-helmet";

import { useParams } from "react-router-dom";
import { filterProductsReq, getCategoryProductsReq } from "../api/requests";
import { default as PageTitle } from "../components/page-title/Index";
import { default as Sidebar } from "../components/sidebar/Index";
import { filterProducts, setDocumentTitle } from '../helper/methods';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";


const Index = ({ webInfo }) => {

    const { id } = useParams();
    const keywords = webInfo.categories.reduce((result, item) => {
        return result + ", " + item.title;
    }, "");
    const [isLoading, setIsLoading] = useState(true);
    const [order, setOrder] = useState('');
    const [isFiltering, setIsFiltering] = useState(false);
    const [category, setCategory] = useState({});
    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');
    const [showFilter, setShowFilter] = useState(0);

    const [filter, setFilter] = useState({
        types: [],
        brands: []
    });

    const handleDisplayFilter = () => {
        setShowFilter(!showFilter)
    }


    const handleFilterReq = (filter, order) => {
        handleDisplayFilter();
        filterProductsReq({ category_id: category.id, ...filter, order: order }).then(res => {
            if (res.status) {
                setProducts(res.products)
            }
            setIsFiltering(false);

        }).catch(e => console.log(e))
    }

    const handleFilterValues = (input_name, checked, value) => {
        setIsFiltering(true);
        let filterP = { ...filterProducts(input_name, checked, value, filter) };
        setFilter({ ...filterP });
        handleFilterReq(filterP, order);
    }

    const handleSort = (e) => {
        setIsFiltering(true);
        setOrder(e.target.value);
        handleFilterReq(filter, e.target.value);
    }
    const handleFilter = (e) => {
        let input_name = e.target.name;
        handleFilterValues(input_name, e.target.checked, e.target.value);

    }


    useEffect(() => {
        setIsLoading(true)
        getCategoryProductsReq(id).then(res => {
            setIsLoading(false);
            setFilter({
                types: [],
                brands: []
            })
            if (res.status) {
                setCategory(res.data.category);
                setProducts(res.data.products);
                setDocumentTitle(webInfo.site_name, res.data.category.title);

            } else {
                setError(res.message);
            }
        }).catch(e => {
            setIsLoading(false);

        });
    }, [id])


    return <div className=" category-page page">
        <Container>

            {
                isLoading ?
                    <LoadingPage />
                    :
                    <>
                        <Helmet>
                            <title>{webInfo.site_name} | {category.title ? category.title : ''} </title>
                            <meta name="description" content={category.title} />
                            <meta name="keywords" content={`${category.title}    ${keywords}`} />
                            <meta name="author" content="Provided by Swan Technology" />
                            <meta property="og:title" content={category.title} />
                            <meta property="og:type" content="article" />
                            <meta property="og:url" content={window.location.href} />
                            <meta property="og:image" content={category.image_path} />
                            <meta name="twitter:title" content={category.title} />
                            <meta name="twitter:description" content={category.title} />
                            <meta name="twitter:image" content={category.image_path}></meta>

                        </Helmet>
                        <Row>
                            <Col md="2">
                                <Sidebar item={category} filter_brands={filter.brands} handleDisplayFilter={handleDisplayFilter} handleFilter={handleFilter} handleSort={handleSort} className={showFilter ? "" : "hide"} />
                            </Col>
                            <Col md="10">
                                <div className=" ">
                                    <>
                                        <PageTitle title={category.title} >
                                            <Button className="bg-grey mobile-btn "
                                                style={{
                                                    color: 'var(--bg-color)', position: 'absolute', right: '0',
                                                    top: '50%',
                                                    transform: 'translateY(-50%)', background: 'none', border: '1px solid var(--bg-color)'
                                                }}
                                                onClick={handleDisplayFilter}
                                            ><FontAwesomeIcon icon={faSlidersH} /></Button></PageTitle>
                                        {
                                            isFiltering ?
                                                <LoadingPage /> :
                                                <Row className="products-section">
                                                    {
                                                        products.map((item, index) =>
                                                            <Col md="3" xs="6" key={index}>
                                                                <ProductItem item={item} />
                                                            </Col>
                                                        )
                                                    }
                                                </Row>
                                        }
                                    </>
                                </div>
                            </Col>
                        </Row>
                    </>

            }
        </Container>
    </div >
}

function mapStateToProps(state) {
    const { webInfo } = state;
    return { webInfo };
}

export default connect(mapStateToProps, null)(Index);