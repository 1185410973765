
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faMobile, faEnvelope } from '@fortawesome/free-solid-svg-icons'

 import { ProfileInfo } from './style';

const Index = ({ address, mobile, email }) => {
    return <ProfileInfo>
        <div>
            {
                email ? <div className="info-row">
                    <FontAwesomeIcon className="icon" icon={faEnvelope} />
                    <span>{email}</span>
                </div> : ''
            }
            {
                mobile ?
                    <div className="info-row">
                        <FontAwesomeIcon className="icon" icon={faMobile} />
                        <span>{mobile}</span>
                    </div> : ''
            }
            {
                address ?
                    <div className="info-row">
                        <FontAwesomeIcon className="icon" icon={faHome} />
                        <span>{address}</span>
                    </div> : ''
            }

        </div>
    </ProfileInfo>
}

export default Index