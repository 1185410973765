
import {
    EmailShareButton,
    EmailIcon,
    FacebookShareButton,
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon
} from "react-share";

const Index = ({ title, description }) => {

    let url = window.location.href;

    return <div className="py-4"  >
        <EmailShareButton url={url} subject={title} body={description} ><EmailIcon size={32} round /></EmailShareButton>
        <FacebookShareButton url={url} quote={title} ><FacebookIcon size={32} round /></FacebookShareButton>
        <TelegramShareButton url={url} title={title}><TelegramIcon size={32} round /></TelegramShareButton>
        <WhatsappShareButton url={url} title={title}><WhatsappIcon size={32} round /></WhatsappShareButton>
        <TwitterShareButton url={url} title={title}><TwitterIcon size={32} round /></TwitterShareButton>

    </div >

}

export default Index