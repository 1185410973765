
import styled from 'styled-components';

export const LoadingIcon = styled.div`
    position:absolute;
    top:0;
    left:50%;
    transform: translateX(-50%);
    width:50px;
    height:50px;
    border-radius:50%;
    background:#000;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
    text-align:center;
    svg{
        width: 40px;
        color: var(--main-color);

    }
`;

export const SendingIcon = styled.div`
    position: relative;
    width: 30px;
    height: 30px;
    background: #f4f4f4;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
    text-align:center;
    svg{
        width: 90%;
        color: var(--main-color);
    }
`;
export const LoadingBox = styled.div`
    position:fixed;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    background: var(--bg-color);
    color: var(--main-color);
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
    text-align:center;
    span{
        margin-bottom:20px;
        display:block;
    }
    svg{
        width: 60px;
    }
`;


export const SendingBox = styled.div`
    position:absolute;
    top:0;
    left:0;
    z-index:100;
    width:100%;
    height:100%;
    background:  ${props => props.bg ? props.bg : '#dddd'};
    color: #fff;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:20px;
    text-align:center;
    span{
        margin-bottom:20px;
        display:block;
    }
    svg{
        width: 60px;
    }
`;
