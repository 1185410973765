

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faCheckCircle, faTruck } from '@fortawesome/free-solid-svg-icons'
import { Topbar } from './style';
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
    Navigation, Autoplay
} from 'swiper';

SwiperCore.use([Autoplay, Navigation]);
const Index = ({ contact, features }) => {


    return <Topbar >
        <div className="container">
            <div className="row">
                <div className="col-md-2 col-3"></div>
                <div className="col-md-10 col-9">
                    <div className="">
                        <div className="list">
                            <Swiper
                                style={{ '--swiper-navigation-color': '#fff', '--swiper-pagination-color': '#fff' }}
                                spaceBetween={0}
                                navigation={false}
                                className="contact-slider"
                                watchSlidesProgress
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    }
                                }}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                            >
                                <SwiperSlide>
                                    <div className='contact-item'>
                                        <FontAwesomeIcon icon={faTruck} className="icon" />
                                        <span className=""> {features.delivery}</span>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className='contact-item'>
                                        <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                                        <span className=""> {features.authentic}</span>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className='contact-item'>
                                        <FontAwesomeIcon icon={faPhone} className="icon" />
                                        <a href={"tel:" + contact.phone} className=""> {features.contact} {contact.phone}</a>
                                    </div>
                                </SwiperSlide>
                            </Swiper>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </Topbar >
}

export default Index