
import styled from 'styled-components';

export const Drawer = styled.div`
    position:fixed;
    z-index:19999;
    top:0;
    left:0;
    width:100vw;
    height:100vh;
    overflow-y:auto;
    background:#fff;
    transform: ${props => props.isDisplayed === true ? 'translateX(0)' : 'translateX(100%)'};
    transition:.3s;
    padding:20px;
    color:#222;
`;

export const DrawerCloseBtn = styled.button`
    position:relative;
    background: #fff;
    box-shadow:none;
    border:none;
    outline:none;
    width:30px;
    height:30px;
    :after,:before{
        content:'';
        position:absolute;
        top:15px;
        height:2px;
        width:100%;
        background:#111;
    }
    :after{ 
        right:0;
        transform: rotateZ(45deg);
    }
    :before{ 
        left:0;
        transform: rotateZ(-45deg);
    }
`;
export const DrawerHeader = styled.div `
    display:flex;
    justify-content: space-between;
    border-bottom:1px solid #f2f2f2;
    padding-bottom:10px;
`;


export const DrawerItem = styled.div `
    display:flex;
    align-items:center;
    padding:10px 5px;
    font-size: 15px;
    a{
        width:100%;
    }
`;

export const DrawerItemIcon = styled.div `
    width:30px;
    padding-inline-end:10px;
    text-align:center;
    color:var(--main-color);
`;


export const DrawerBlock = styled.div`
    padding: 10px 0;
    border-bottom:1px solid #f3f3f3;
`;