

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade } from "react-reveal";

import { ContactBox } from "./style.js";

const Index = (props) => {
    const { icon, children } = props
    return <Fade left>
        <ContactBox >
            <div className="icon-box">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="contact-box-text">
                {children}
            </div>
        </ContactBox>
    </Fade>
};

export default Index