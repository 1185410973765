


import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NoProfileImg from '../../assets/imgs/no-profile-img.png'
import { Btn } from '../style';
import { ProfileHead } from './style';

const Index = ({  img, full_name, setShowImagesEditModal, setShowPersonalInfoEditModal }) => {
    return <ProfileHead>
        <div className="cover">

        </div>
        <div className="profile-head-bottom">
            <div className="d-flex align-items-center">
                <img className="photo" src={img ?? NoProfileImg} alt=""/>
                <h2 className='username'>{full_name}</h2>
            </div>

            <div>
                <Btn bg="#f2f2f2" onClick={setShowImagesEditModal}><FontAwesomeIcon icon={faPencilAlt} />  Edit Photo   </Btn>
                <Btn bg="#f2f2f2" onClick={setShowPersonalInfoEditModal}><FontAwesomeIcon icon={faPencilAlt} /> Edit Personal Info </Btn>
            </div>

        </div>
    </ProfileHead>
}

export default Index