
import styled from 'styled-components';

import { Box } from '../style.js'

export const ProfileContactInfo = styled(Box)`
    padding:25px;
    margin-bottom:50px;
    font-size:13px;
    position:relative;
    font-size:15px;
    .contact-info-item{
        display:flex;
        margin-bottom:15px;
    }
    .item-name{
        width:160px;
    }
    .item-name .icon{
        width: 25px;
        margin-inline-end: 10px;
        font-size: 17px;
        color:var(--bg-main-color);
    }

`;

export const ProfileHead = styled.div`
    background:#fff;
    margin-bottom:50px;
    font-size:13px;
    position:relative;
    padding-top:50px;
    .cover{
        width:100%;
        height:60px;
        background: var(--bg-color);
    }
    .profile-head-bottom{
        width:100%;
        margin-top: -100px;
        display:flex;
        align-items:center;
        justify-content: space-between;
        padding : 0 20px;
    }
    img.photo{
        width:140px;
        height:140px;
        border-radius:50%;
        background:#fff;
        position:relative;
        z-index:10;
        border:2px solid var(--bg-color)
    }
    .username{
        padding:10px 30px;
        color:#fff;
        margin: 0;
        font-size:22px;
    }
    button{
        margin-inline-end:5px
    }
    @media ( max-width: 992px){
        .profile-head-bottom{  
            display:block;
            bottom: 0;
        }
    }

`;

export const ProfileInfo = styled(Box)`
    padding:25px;
    margin-bottom:50px;
    font-size:13px;
    position:relative;
    .username{
        font-size:18px;
    }
    .info-row{
        color:#222;
        margin: 10px 0;
        font-size:15px;
        display: flex;
        align-items: center;
    }
    .info-row .icon{
        width:20px;
        height:20px;
        margin-inline-end:10px
    }
    
`;