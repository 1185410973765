
import styled from 'styled-components';


export const Sidebar = styled.div`
    padding: 70px 0;
    height: 100%;
    width: 100%;
    transition:.3s;
    
    @media (max-width:992px) {
        position:fixed;
        right:0;
        top:120px;
        z-index:10;
        overflow-y:auto;
        box-shadow: 1px 2px 5px #ddd;
        background:#fff;
        padding:70px 20px 40px;
        &.hide{
            transform:translateX(100%);
        }
         
    }

`;


export const FilterBox = styled.div`
    padding:10px 0;
    margin-bottom: 10px;
    
    &:not(:last-child){
        border-bottom:1px solid #ddd;
    }
    .filter-box-choices{
        max-height:320px;
        overflow:auto;
    }
    .filter-box-title{
        font-size: 14px;
        font-weight:bold;
    }
    form{
        padding-bottom:20px;
    }
    form input{
    border: 1px #ccc solid;
    width: 100%;
    }
    .filter-box-title::after{
        content: '';
        display: block;
        background: var(--main-color);
        width: 20%;
        height: 2px;
        margin-top: 5px;
    }
    
`;


export const FilterChoice = styled.div`
    margin-bottom: 5px;
    span{
        margin-inline-start:5px;
    }
    .form-select{
        padding: .2rem 2rem .2rem .2rem;
        font-size:inherit;
    }
    .form-select:focus{
        box-shadow: 0 1px 5px var(--main-color);
        border: 1px solid  var(--main-color);
    }

`;