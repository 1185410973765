
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactWhatsapp from 'react-whatsapp';

const Index = ({ number, message }) => {
    return <ReactWhatsapp number={number} message={message} className="btn whatsapp-btn" ><FontAwesomeIcon icon={faWhatsapp} /> </ReactWhatsapp>

}

export default Index