
import { Form } from "react-bootstrap";
import { FilterBox } from "./style";

const Index = ({ title, search, children, handleChangeFilter }) => {

    return <FilterBox>
        <h6 className="filter-box-title">{title}</h6>
        {
            search ? <Form >
                <input onChange={handleChangeFilter} placeholder="Search .." style={{ padding:'2px 7px'}} />
            </Form> : ''
        }
        <div className="filter-box-choices">
            {
                children
            }
        </div>

    </FilterBox>
}

export default Index
